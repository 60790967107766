import React, {useState} from 'react'
import './App.css';
import {AnimatePresence} from 'framer-motion'
import './styles/style.css';
import Login from './components/base/Login';
import Main from './components/Main';
import Loader from './components/base/Loader';
import StatusRequest from './components/base/StatusRequest';
import {Provider} from 'react-redux'
import store from './store'
import axios from 'axios'


function App() {
  const [loader, setLoader] = useState(false)
  const [statusRequest, setStatusRequest] = useState({
    /*status : false, 
    message : "Nous n'avons pas pu traiter votre demande, veuillez réessayer"*/
  })

  const [clientConnected, setClientConnected] = useState(false)


  if(localStorage.getItem('token') && !clientConnected) {
     setClientConnected(true)
  } else if(!localStorage.getItem('token') && clientConnected) {
    setClientConnected(false)
  }
  

  axios.interceptors.response.use(undefined, err => {
    if(err.response.status === 401) {
      localStorage.clear()
      setClientConnected(false)
      console.log(`This is the error if : ${err}`)

      return setStatusRequest({
        status : false, 
        message : 'Utilisateur non authentifié'
      })
    } else {
      return err
    }
  })

  return (
    <AnimatePresence exitBeforeEnter initial = {false}>
    <Provider store = {store}>

      <StatusRequest
          statusRequest = {statusRequest}
          setStatusRequest = {setStatusRequest}    
      />
      <AnimatePresence exitBeforeEnter initial = {false}>
      {
          loader ? 
              <Loader />
          :
          null
      }
      </AnimatePresence>

      {
        clientConnected ?
          <Main 
            setStatusRequest = {setStatusRequest}
            setLoader = {setLoader}
          />
        :
          <Login 
            setClientConnected = {setClientConnected}
            setStatusRequest = {setStatusRequest}
            setLoader = {setLoader}
          />
      }
    </Provider>
    </AnimatePresence>
  );
}

export default App;
