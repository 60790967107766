import {createStore, combineReducers} from 'redux'
import  brandsReducer  from './reducers/brandsReducer'
import  flagsReducer  from './reducers/flagsReducer'
import stickersReducer from './reducers/stickersReducer'

export default createStore(
    combineReducers({
        flags : flagsReducer, 
        brands : brandsReducer, 
        stickers : stickersReducer
    }),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)