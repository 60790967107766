import React, {useState, useRef, useEffect} from 'react'
import {conditionnalInputFlag} from '../../config/formProcessingVariables'
import { motion, AnimatePresence } from 'framer-motion'
import { hostname } from '../../config/utils'
import axios from 'axios'
import editIcon from '../../images/edit-icon.png'
import { FileFlag, NameFlag } from './AddFlag'
import {ReactComponent as CloseSVG} from '../../svg/icon-close.svg'
import { useSelector, useDispatch } from 'react-redux'
import { flagsSelector } from '../../store/selectors/flagsSelector'
import { deleteFlagsAction, updateFlagsAction } from '../../store/actions/flagsActions'
import DeletionConfirmation from '../base/DeletionConfirmation'
import {Link} from 'react-router-dom'
import { Tutorials } from '../base/Dashboard'

export default function CatalogFlag(props) {
   //State that initialize component
   const [dataFlags, setDataFlags] = useState([])
   const [checkboxesChecked, setCheckboxesChecked] = useState([])
   const [dataStatus, setDataStatus] = useState(false)
   const [deletionConfirmation, setDeletionConfirmation] = useState(false)
   const [detectSelect, setDetectSelect] = useState(false)
   const [tutorialBox, setTutorialBox] = useState({})
   const dispatch = useDispatch()

   //REDUX
   const allFlags = useSelector(flagsSelector)

   //Initialize data flags
   if(allFlags.length > 0 && !dataStatus) {
        setDataFlags(allFlags)
        setDataStatus(true)
        if(props.header) {
            props.setHeader(false)
        }

   }


   //State to manipulate the component 
   const [updateBox, setUpdateBox] = useState(false)
   const [flag, setFlag] = useState({})
   const [flagValidation, setFlagValidation] = useState({})
   const [filePreview, setFilePreview] = useState('')


   const handleChangeInput = (e) => {
       if(e.target.name === "linkFlag") {
           const fileUploaded = e.target.files[0] 
           if(fileUploaded) {
               setFlag(state => {
                   return {
                       ...state, 
                       [e.target.name] : fileUploaded
                   }
               })
               setFilePreview(URL.createObjectURL(fileUploaded))

           } else {
               setFlag(state => {
                   return {
                       ...state, 
                       [e.target.name] : ''
                   }
               })
               setFilePreview("")
           }
           setFlagValidation(state => {
               return {
                   ...state, 
                   [e.target.name] : conditionnalInputFlag(e)
               }
           })
       } else {
           const value = e.target.value
           setFlag(state => {
               return {
                   ...state, 
                   [e.target.name] : value
               }
           })
   
           setFlagValidation(state => {
               return {
                   ...state,
                   [e.target.name] : conditionnalInputFlag(e)
           }})
       }
   }

   const handleDelete = (e) => {
        e.preventDefault()
        props.setLoader(true)
        if(checkboxesChecked.length > 0) {
            setDeletionConfirmation(false)
            axios.post(`${hostname}/api/flags/delete`, checkboxesChecked, 
                {headers : {
                    "authorization" : localStorage.getItem('token')
                }}
                )
                .then(res => { 
                    dispatch(deleteFlagsAction(checkboxesChecked))
                    setDeletionConfirmation(false)
                    props.setStatusRequest(res.data)
                    props.setLoader(false)
                    setCheckboxesChecked([])

                })
                .catch(err => {
                    props.setLoader(false)
                    setDeletionConfirmation(false)
                    if(err.response === undefined) {
                    props.setStatusRequest({
                        status : false, 
                        message : "Vous avez été déconnécté du serveur, veuillez vous reconnecter."
                    })
                } else {
                    props.setStatusRequest(err.response.data)
                }
                })
        } else {
            props.setLoader(false)
            props.setStatusRequest({
                status : false, 
                message : "Veuillez sélectionner un drapeau"
            })
        }

    }

   return (
       <React.Fragment>
            <AnimatePresence exitBeforeEnter initial = {false}>
            {
            Object.entries(tutorialBox).length > 0  ? 
            <Tutorials
                setTutorialBox = {setTutorialBox}
                tutorialBox = {tutorialBox}
            />
            
            :

            null
            }
            </AnimatePresence>
           <div className = "catalog__header--title">Catalogue des drapeaux</div>
           <AnimatePresence exitBeforeEnter initial = {false}>
           {updateBox ? 
               <UpdateWindow 
                   flag = {flag}
                   flagValidation = {flagValidation}
                   setFlagValidation = {setFlagValidation}
                   handleChangeInput = {handleChangeInput}
                   filePreview = {filePreview}
                   updateBox = {updateBox}
                   setUpdateBox = {setUpdateBox}
                   setLoader = {props.setLoader}
                   setStatusRequest = {props.setStatusRequest}
                   updateFlagEndpoint = {props.updateFlagEndpoint}
                   token = {props.token}
                   setDataFlags = {setDataFlags}
                   checkboxesChecked={checkboxesChecked}
                   setTutorialBox = {setTutorialBox}
               />
           : null
           }
           </AnimatePresence>
           
           <AnimatePresence exitBeforeEnter initial = {false}>
           {
                deletionConfirmation ? 
                    <DeletionConfirmation 
                        setDeletionConfirmation = {setDeletionConfirmation}
                        handleDelete = {handleDelete}
                    />
                :
                null
           }
           </AnimatePresence>

       <motion.div 
           exit = {{x : 1000}}
           animate = {{x : 0}}
           initial = {{x : 1000}}
           transition = {{
               type : "linear"
           }}
           className = "catalog catalog__flag"
       >
           <HeaderCatalog 
               setDataFlags = {setDataFlags}
               allFlags = {allFlags}
               checkboxesChecked = {checkboxesChecked}
           />
           <CatalogContent 
               dataFlags = {dataFlags}
               checkboxesChecked = {checkboxesChecked}
               setCheckboxesChecked = {setCheckboxesChecked}
               setDataFlags = {setDataFlags}
               detectSelect = {detectSelect}
               setDetectSelect = {setDetectSelect}
           />

       </motion.div>
       <Cart 
           allFlags = {allFlags}
           checkboxesChecked = {checkboxesChecked}
           setCheckboxesChecked = {setCheckboxesChecked}
           dataFlags = {dataFlags}
           setDataFlags = {setDataFlags}
           updateBox = {updateBox}
           setUpdateBox = {setUpdateBox}
           setFlag = {setFlag}
           setFilePreview = {setFilePreview}
           setLoader = {props.setLoader}
           setStatusRequest = {props.setStatusRequest}
           setFlagValidation = {setFlagValidation}
           deleteFlagEndpoint = {props.deleteFlagEndpoint}
           token = {props.token}
           setDeletionConfirmation = {setDeletionConfirmation}
           detectSelect = {detectSelect}
           setHeader = {props.setHeader}
           header = {props.header}
       />


       </React.Fragment>
   )
}


function HeaderCatalog(props) {
   const [searchFlag, setSearchFlag] = useState("")

   

   const handleSearchFlag = async (e) => {
       const value = e.target.value
       setSearchFlag(value)
       const searchString = value.trim().toLowerCase()
       const searchData = await props.allFlags.filter(flag => `${flag.nameFlag}`.toLowerCase().match(searchString))

       const dataFilter = await searchData.filter(flag => !props.checkboxesChecked.includes(flag.idFlag))

       props.setDataFlags(dataFilter)
   }

   return(
       <div className = "catalog__header">

           <div className = "catalog__header--filter">
               <input type = "text" onChange = {handleSearchFlag} value = {searchFlag} placeholder = "Rechercher un drapeau"/>
               <Link to = {`/CazaSticker2/flag/add`}>Ajouter</Link>
           </div>
       </div>
   )
}

function CatalogContent(props) {


    useEffect(() => {
        if(props.detectSelect) {
            const timer = setTimeout(() => {
                props.setDetectSelect(false)
            }, 650);
            return () => clearTimeout(timer);
        }
    })


   

   const handleOnChangeCheckedState = async(position) => {
       const checkboxSelected = props.dataFlags.filter((flag, index) => index === position)
       const checkboxesCheckedCurrent = props.checkboxesChecked
       checkboxesCheckedCurrent.push(checkboxSelected[0].idFlag)
       props.setCheckboxesChecked(checkboxesCheckedCurrent)
       
       const dataFilter = await props.dataFlags.filter(flag => !props.checkboxesChecked.includes(flag.idFlag))
       props.setDataFlags(dataFilter)
       props.setDetectSelect(true)
       
   }
   return ( 
       <div className = "catalog__table">
           <table>
               <thead>
                   <tr>
                       <th>Drapeau</th>    
                       <th>Nom du pays</th> 
                   </tr> 
               </thead>
               <tbody>

                   { 
                   Object.entries(props.dataFlags).length > 0 ? 
                        <AnimatePresence>{
                       props.dataFlags.map((flag, index) => (
                           <motion.tr 
                           exit = {{x : 1000, }}
                           animate = {{x : 0 }}
                           initial = {{x : 1000}}
                           transition = {{
                               type : "linear", 
                           }}
                           key = {flag.idFlag} 
                           onClick = {(e) => handleOnChangeCheckedState(index,e)}>
                               <td><img src = {`${hostname}/api/images/flags/${flag.linkFlag}`} alt = {flag.nameFlag} /></td>
                               <td>{flag.nameFlag}</td>
                           </motion.tr>
                       ))
                        }
                        </AnimatePresence>
                    : 
                       <motion.tr 
                       exit = {{opacity : 1, transitionDelay : "800ms" }}
                       animate = {{opacity: 1, transitionDelay : "800ms" }}
                       initial = {{opacity : 0, transitionDelay : "800ms" }}
                       transition = {{
                        type : "linear", 
                        default : {duration : 0}
                        }}
                       id = 'catalog__table--noData'>
                           <td colSpan = {12}>Aucun drapeau</td>

                       </motion.tr>
                   }

               </tbody>
           </table>

       </div>
   )
}

function Cart(props) {
   const cart = useRef(null)

   //REDUX 
   const allFlags = useSelector(flagsSelector)
    const dispatch = useDispatch()

    

   const handleClickCart = (e) => {
       e.preventDefault()
       const cartCurrent = cart.current
       cartCurrent.classList.toggle('is-cart-open')
       if(props.header) {
           props.setHeader(false)
       }

   }

   const handleDeselect = async (idFlag) => {
        const newCheckboxes = props.checkboxesChecked.filter(check => check !== idFlag)
        await props.setCheckboxesChecked(newCheckboxes)     
        const newData = await allFlags.filter(flag => !newCheckboxes.includes(parseInt(flag.idFlag)))
        props.setDataFlags(newData)
   }

   const handleUpdate = async (e, checked) => {
       e.preventDefault()
       await props.allFlags.map(flag => {
           if(flag.idFlag === checked) {
               props.setFlag(flag)
               props.setFilePreview(`${hostname}/api/images/flags/${flag.linkFlag}`)
               for (const [key, value] of Object.entries(flag)) {
                   if (value) {
                       props.setFlagValidation(state => {
                           return {
                               ...state,
                               [key] : true
                       }})
                   } 
                 }
           }

       })
       props.setUpdateBox(true)
   }


   const handleDelete = (e) => {
       e.preventDefault()

       if(props.checkboxesChecked.length > 0) {
            props.setDeletionConfirmation(true)
       } else {
           props.setStatusRequest({
               status : false, 
               message : "Veuillez sélectionner un drapeau"
           })
       }

   }

   const handleClean = (e) => {
       e.preventDefault()
       props.setCheckboxesChecked([])
        props.setDataFlags(allFlags)
       
   }


   return (
       <div className = "cart">
            <div className = {props.detectSelect ? "cart--button triggerWizz" : "cart--button"}  onClick = {handleClickCart}>
           <button>
               <span>{props.checkboxesChecked.length}</span>
           </button>
           </div>

           <div className = "cart__content" ref = {cart}>
               <div className = "cart__content__buttons">
                   <button id = "delete" onClick = {handleDelete}>Supprimer la sélection</button>
               </div>
               {
                   props.checkboxesChecked.length > 5 ? 
                   <div className = "cart__clean">
                        <motion.button
                        exit = {{transform : "scale(0)"}}
                        animate = {{transform : "scale(1)"}}
                        initial = {{transform : "scale(0)"}}
                        onClick = {handleClean}
                        >
                                Vider la sélection
                        </motion.button>
                   </div>
                    :
                        null
                    
               }
               <ul className = "cart__content__list">
                   { props.checkboxesChecked.length === 0 ? 
                       <motion.li
                           exit = {{opacity : 0 }}
                           animate = {{opacity : 1 }}
                           initial = {{opacity : 0 }}
                           className = "cart__content__list__noData"
                       ><span>Aucun drapeau sélectionné</span></motion.li>

                       :
                        
                       props.allFlags.map((flag) => (
                           <AnimatePresence>{
                           props.checkboxesChecked.map((checked, index) => (
                               checked === flag.idFlag ? 
                                   <motion.li 
                                       exit = {{x : -500}}
                                       animate = {{x : 0 }}
                                       initial = {{x : -500 }}
                                       transition = {{
                                           default : {duration : 0.2}
                                       }}
                                       key = {flag.idFlag}
                                   >
                                       <button className = "deselect" onClick = {(e) => handleDeselect(flag.idFlag)}>-</button>
                                       <img src = {`${hostname}/api/images/flags/${flag.linkFlag}`} alt = "H" />
                                       <span>{flag.nameFlag}</span>
                                       <button className = "update" onClick = {(e)=> handleUpdate(e, checked)}><img src = {editIcon} alt = "edit"/></button>

                                   </motion.li>
                               : 

                               null
                           ))
                            }</AnimatePresence>
                       ))
                   }
               </ul> 

           </div>
           
       </div>
   )


}

function UpdateWindow(props) {
    const allFlags = useSelector(flagsSelector)
    const dispatch = useDispatch()
   const handleClose = (e) => {
       e.preventDefault()
       props.setUpdateBox(!props.updateBox)
   }

   const handleSubmitUpdate = (e) => {
       e.preventDefault()
       if(JSON.stringify(props.flag) === JSON.stringify(allFlags.find(flagFind => flagFind.idFlag === props.flag.idFlag))) {
        props.setUpdateBox(false)
        return props.setStatusRequest({
            status : true, 
            message : "Aucun changement de données n'a été détécté"
        })
        }
       props.setLoader(true)


       if(props.flagValidation.nameFlag && props.flagValidation.linkFlag) {
           var formContainer = new FormData()
           formContainer.append('nameFlag', props.flag.nameFlag)
           formContainer.append('idFlag', props.flag.idFlag)
           formContainer.append('file', props.flag.linkFlag)

           axios.post(`${hostname}/api/flags/update`, formContainer, 
               {headers : {
                   "authorization" : localStorage.getItem('token')
               }})
           .then(async res => { 
               await dispatch(updateFlagsAction(res.data.updatedFlag))
               //props.setDataFlags(allFlags.filter(flag => !props.checkboxesChecked.includes(flag.idFlag) ))
               props.setLoader(false)
               props.setUpdateBox(!props.updateBox)
               props.setStatusRequest(res.data)

           })
           .catch(err => {
                props.setLoader(false)
                if(err.response === undefined) {
                    props.setStatusRequest({
                        status : false, 
                        message : "Vous avez été déconnecté du serveur, veuillez vous reconnecter."
                    })
                } else {
                    props.setStatusRequest(err.response.data)
                }
           })

       } else {
           props.setLoader(false)
           const validationRequest = [
               {nameInput : 'nameFlag', message : "Veuillez entrer le nom du pays"}, 
               {nameInput : 'fileFlag', message : "Veuillez télécharger un drapeau"}
           ]

           validationRequest.map(validation => {
               if(!props.flagValidation[validation.nameInput] || props.flagValidation[validation.nameInput] === null) {
                   props.setFlagValidation(state => {
                       return {
                           ...state, 
                           [validation.nameInput] : false
                       }
                   })
               }

               if(!props.flagValidation.nameFlag && !props.flagValidation.fileFlag) {
                   props.setStatusRequest({
                       status : false, 
                       message : "Votre demande est incomplète"
                   })
               } else {
                   if (!props.flagValidation[validation.nameInput]) {
                       props.setStatusRequest({
                           status : false, 
                           message : validation.message
                       })
                   }
               }
           })
       }
   }

   return (
       <motion.div 
       exit = {{transform : "scale(0)"}}
       animate = {{transform : "scale(1)"}}
       initial = {{transform : "scale(0)"}}
       transition= {{
           type : "linear"
       }}
       className = "catalog__update"
       >
           <div className = "catalog__update__close"><CloseSVG onClick = {handleClose}/></div>
           <form className = "addFlag" onSubmit = {handleSubmitUpdate}>
               <div className = "catalog__update__element"> 
               <NameFlag 
                       flag = {props.flag}
                       flagValidation = {props.flagValidation}
                       handleChangeInput = {props.handleChangeInput}
                   />

               <FileFlag
                   flag = {props.flag}
                   flagValidation = {props.flagValidation}
                   handleChangeInput = {props.handleChangeInput}
                   filePreview = {props.filePreview}
                   setTutorialBox ={props.setTutorialBox}
               />

               </div>
               <div className = "add__button"><input type = "submit" value = "Valider" /></div>


           </form>

       </motion.div>
   )
}
