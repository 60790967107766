import React, {useState, useRef, useEffect} from 'react'
import {AnimatePresence, motion} from 'framer-motion'
import {conditionnalInputBrand} from '../../config/formProcessingVariables'
import {hostname} from '../../config/utils'
import editIcon from '../../images/edit-icon.png'
import { FileBrand, NameBrand, FlagBrand } from './AddBrand'
import axios from 'axios'
import {ReactComponent as CloseSVG} from '../../svg/icon-close.svg'
import noImage from '../../images/noImage.png'
import { useSelector, useDispatch } from 'react-redux'
import { brandsSelector } from '../../store/selectors/brandsSelector'
import { flagsSelector } from '../../store/selectors/flagsSelector'
import { deleteBrandsAction, updateBrandsAction } from '../../store/actions/brandsActions'
import DeletionConfirmation from '../base/DeletionConfirmation'
import {Link} from 'react-router-dom'
import { Tutorials } from '../base/Dashboard'


export default function CatalogBrand(props) {
    //State that initialize component
    const [dataBrands, setDataBrands] = useState([])
    const [checkboxesChecked, setCheckboxesChecked] = useState([])
    const [dataStatus, setDataStatus] = useState(false)
    const [deletionConfirmation, setDeletionConfirmation] = useState(false)
    const [detectSelect, setDetectSelect] = useState(false)
    const [tutorialBox, setTutorialBox] = useState(false)
    const dispatch = useDispatch()

    const allBrands = useSelector(brandsSelector)
    //Initialize data flags
    if(allBrands.length > 0 && !dataStatus) {
        setDataBrands(allBrands)
        setDataStatus(true)
        if(props.header) {
            props.setHeader(false)
        }
    }

    //State to manipulate the component 
    const [updateBox, setUpdateBox] = useState(false)
    const [brand, setBrand] = useState({})
    const [brandValidation, setBrandValidation] = useState({})
    const [filePreview, setFilePreview] = useState('')

    const handleChangeInput = (e) => {
        if(e.target.name === "linkBrand") {
            const fileUploaded = e.target.files[0]
            if(fileUploaded) {
                setBrand(state => {
                    return {
                        ...state, 
                        [e.target.name] : fileUploaded
                    }
                })
                setFilePreview(URL.createObjectURL(fileUploaded))
            } else {
                setBrand(state => {
                    return {
                        ...state, 
                        [e.target.name] : ''
                    }
                })
                setFilePreview('')
            }
            setBrandValidation(state => {
                return {
                    ...state, 
                    [e.target.name] : conditionnalInputBrand(e)
                }
            })
        } else {
            const value = e.target.value
            setBrand(state => {
                return {
                    ...state, 
                    [e.target.name] : value
                }
            })
    
            setBrandValidation(state => {
                return {
                    ...state,
                    [e.target.name] : conditionnalInputBrand(e)
            }})
        }
    }

    const handleDelete = async (e) => {
        e.preventDefault()
        props.setLoader(true)
        if(checkboxesChecked.length > 0) {
            axios.post(`${hostname}/api/brands/delete`, checkboxesChecked, {
                headers : {
                    "authorization" : localStorage.getItem('token')
                }
            })
                .then(res => { 
                    dispatch(deleteBrandsAction(checkboxesChecked))
                    setDeletionConfirmation(false)
                    props.setStatusRequest(res.data)
                    props.setLoader(false)
                    setCheckboxesChecked([])
                })
                .catch(err => {
                    //console.log(err)
                    props.setLoader(false)
                    setDeletionConfirmation(false)
                    if(err.response === undefined) {
                        props.setStatusRequest({
                            status : false, 
                            message : "Vous avez été déconnécté du serveur, veuillez vous reconnecter."
                        })
                    } else {
                        props.setStatusRequest(err.response.data)
                    }
                })
        } else {
            props.setLoader(false)
            props.setStatusRequest({
                status : false, 
                message : "Veuillez sélectionner une marque"
            })
        }
    }

    return ( 
        <React.Fragment>
            <AnimatePresence exitBeforeEnter initial = {false}>
                {
                Object.entries(tutorialBox).length > 0  ? 
                <Tutorials
                    setTutorialBox = {setTutorialBox}
                    tutorialBox = {tutorialBox}
                />
                :

                null
            }
            </AnimatePresence>
            <div className = "catalog__header--title">Catalogue des marques</div>
                <AnimatePresence exitBeforeEnter initial = {false}>
                {updateBox ? 

                    <UpdateWindow 
                        allFlags = {props.allFlags}
                        brand = {brand}
                        brandValidation = {brandValidation}
                        handleChangeInput = {handleChangeInput}
                        filePreview = {filePreview}
                        updateBox = {updateBox}
                        setUpdateBox = {setUpdateBox}
                        setLoader =  {props.setLoader}
                        setStatusRequest = {props.setStatusRequest}
                        socket = {props.socket}
                        setTutorialBox = {setTutorialBox}
                    />

                : null
                }
                </AnimatePresence>
                <AnimatePresence exitBeforeEnter initial = {false}>
                {
                    deletionConfirmation ? 
                        <DeletionConfirmation
                            setDeletionConfirmation = {setDeletionConfirmation}
                            handleDelete = {handleDelete}
                        />
                    :
                    null
                }
                </AnimatePresence>
            <motion.div 
            exit = {{x : 1000}}
            animate = {{x : 0}}
            initial = {{x : 1000}}
            transition = {{
                type : "linear"
            }}
            className = "catalog catalog__flag"
            >
                <HeaderCatalog 
                    setDataBrands = {setDataBrands}
                    checkboxesChecked = {checkboxesChecked}
                />
                <CatalogContent 
                    dataBrands = {dataBrands}
                    checkboxesChecked = {checkboxesChecked}
                    setCheckboxesChecked = {setCheckboxesChecked}
                    setDataBrands = {setDataBrands}
                    detectSelect = {detectSelect}
                    setDetectSelect = {setDetectSelect}
                />
            </motion.div>
            <Cart 
                checkboxesChecked = {checkboxesChecked}
                setCheckboxesChecked = {setCheckboxesChecked}
                dataBrands = {dataBrands}
                setDataBrands = {setDataBrands}
                updateBox = {updateBox}
                setUpdateBox = {setUpdateBox}
                setBrand = {setBrand}
                setFilePreview = {setFilePreview}
                setLoader = {props.setLoader}
                setStatusRequest = {props.setStatusRequest}
                setBrandValidation = {setBrandValidation}
                deleteBrandEndpoint = {props.deleteBrandEndpoint}
                socket = {props.socket}
                setDeletionConfirmation = {setDeletionConfirmation}
                detectSelect = {detectSelect}
                setHeader = {props.setHeader}
                header = {props.header}
            />
    </React.Fragment>
    )
}


function UpdateWindow(props) {
    const dispatch = useDispatch()

    const handleClose = (e) => {
        e.preventDefault()
        props.setUpdateBox(!props.updateBox)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        props.setLoader(true)
        if(props.brandValidation.nameBrand && props.brandValidation.idFlag && props.brandValidation.linkBrand) {
            const formContainer = new FormData()
            formContainer.append('idBrand', props.brand.idBrand)
            formContainer.append('nameBrand', props.brand.nameBrand)
            formContainer.append('flagBrand', props.brand.idFlag)
            formContainer.append('file', props.brand.linkBrand) 

            axios.post(`${hostname}/api/brands/update`, formContainer, {
                headers : {
                    "authorization" : localStorage.getItem('token')
                }
            })
            .then(async (res) => { 
                dispatch(updateBrandsAction(res.data.updatedBrand))
                props.setUpdateBox(!props.updateBox)
                props.setLoader(false)
                props.setStatusRequest(res.data)
            })
            .catch(err => {
                console.log(err)
                props.setLoader(false)

                if(err.response === undefined) {
                    props.setStatusRequest({
                        status : false, 
                        message : "Vous avez été déconnécté du serveur, veuillez vous reconnecter."
                    })
                } else {
                    props.setStatusRequest(err.response.data)
                }
            })

        } else {
            props.setLoader(false)
            const validationRequest = [
                {nameInput : 'nameBrand', message : "Veuillez entrer le nom de la marque"}, 
                {nameInput : 'idFlag', message : "Veuillez choisir un pays"}, 
                {nameInput : 'linkBrand', message : "Veuillez télécharger la marque"} 
            ]

            validationRequest.map(validation => {
                if(!props.brandValidation[validation.nameInput] || props.brandValidation[validation.nameInput] === null) {
                    props.setBrandValidation(state => {
                        return {
                            ...state, 
                            [validation.nameInput] : false
                        }
                    })
                }

                const falseValidation = Object.values(props.brandValidation).filter(validation => validation === false).length
                if(falseValidation > 1) {
                    props.setStatusRequest({
                        status : false, 
                        message : "Votre demande est incomplète"
                    })
                } else {
                    if (!props.brandValidation[validation.nameInput]) {
                        props.setStatusRequest({
                            status : false, 
                            message : validation.message
                        })
                    }
                }
            })

        }
    }



    return ( 
        <React.Fragment>

            <motion.form 
                exit = {{transform : "scale(0)"}}
                animate = {{transform : "scale(1)"}}
                initial = {{transform : "scale(0)"}}
                transition = {{
                    type : 'linear'
                }}
                className = "catalog__update" 
                onSubmit = {handleSubmit}
            >
                <div className = "catalog__update__close"><CloseSVG onClick = {handleClose}/></div>
                <div className = "catalog__update__element">
                    <FlagBrand 
                        allFlags = {props.allFlags}
                        brand = {props.brand}
                        brandValidation = {props.brandValidation}
                        handleChangeInput = {props.handleChangeInput}
                    />

                    <NameBrand 
                        allFlags = {props.allFlags}
                        brand = {props.brand}
                        brandValidation = {props.brandValidation}
                        handleChangeInput = {props.handleChangeInput}
                    />

                    <FileBrand 
                        brand = {props.brand}
                        brandValidation = {props.brandValidation}
                        handleChangeInput = {props.handleChangeInput}
                        filePreview = {props.filePreview}
                        setTutorialBox = {props.setTutorialBox}
                    />
                    <div className = "add__button"><input type = "submit" value = "Valider" /></div>
                </div>


            </motion.form>
        </React.Fragment>
    )
}

function Cart(props) {
    const cart = useRef(null)
    const allBrands = useSelector(brandsSelector)

    const handleClickCart = (e) => {
        e.preventDefault()
        const cartCurrent = cart.current
        cartCurrent.classList.toggle('is-cart-open')
        if(props.header) {
            props.setHeader(false)
        }
    }

    const handleDeselect = async (idBrand) => {
        const newCheckboxes = props.checkboxesChecked.filter(check => check !== idBrand)
        props.setCheckboxesChecked(newCheckboxes)     
        props.setDataBrands(allBrands.filter(brand => !newCheckboxes.includes(brand.idBrand)))

    }

    const handleUpdate = async (e, checked) => {
        e.preventDefault()
        await allBrands.map(brand =>  {
            if(brand.idBrand === checked) {
                props.setUpdateBox(false)
                props.setBrand(brand)
                props.setFilePreview(`${hostname}/api/images/brands/${brand.linkBrand}`)
                for (const [key, value] of Object.entries(brand)) {
                    if (value) {
                        props.setBrandValidation(state => {
                            return {
                                ...state,
                                [key] : true
                        }})
                    } 
                  }
            }
        })
        props.setUpdateBox(true)
    }

    const handleDelete = async (e) => {
        e.preventDefault()
        if(props.checkboxesChecked.length > 0) {
            props.setDeletionConfirmation(true)
        } else {
            props.setLoader(false)
            props.setStatusRequest({
                status : false, 
                message : "Veuillez sélectionner une marque"
            })
        }
    }

    const handleClean = (e) => {
        e.preventDefault()
        props.setCheckboxesChecked([])
         props.setDataBrands(allBrands)
        
    }

    return (
        <div className = "cart">
            <div className = {props.detectSelect ? "cart--button triggerWizz" : "cart--button"}  onClick = {handleClickCart}>
            <button >
                <span>{props.checkboxesChecked.length}</span>
            </button>
            </div>

            <div className = "cart__content" ref = {cart}>
                <div className = "cart__content__buttons">
                    <button id = "delete"onClick = {handleDelete}>Supprimer la sélection</button>
                </div>
                {
                   props.checkboxesChecked.length > 5 ? 
                   <div className = "cart__clean">
                        <motion.button
                        exit = {{transform : "scale(0)"}}
                        animate = {{transform : "scale(1)"}}
                        initial = {{transform : "scale(0)"}}
                        onClick = {handleClean}
                        >
                                Vider la sélection
                        </motion.button>
                   </div>
                    :
                        null
                    
               }
                <ul className = "cart__content__list">
                    { props.checkboxesChecked.length === 0 ? 
                        <motion.li
                            exit = {{opacity : 0 }}
                            animate = {{opacity : 1 }}
                            initial = {{opacity : 0 }}
                            className = "cart__content__list__noData"
                        ><span>Aucun drapeau sélectionné</span></motion.li>

                        :

                        allBrands.map((brand) => (
                            <AnimatePresence>{
                            props.checkboxesChecked.map((checked, index) => (
                                checked === brand.idBrand ? 
                                    <motion.li 
                                        exit = {{x : -500}}
                                        animate = {{x : 0 }}
                                        initial = {{x : -500 }}
                                        transition = {{
                                            default : {duration : 0.2}
                                        }}
                                        key = {brand.idBrand}
                                    >
                                        <button className = "deselect" onClick = {(e) => handleDeselect(brand.idBrand)}>-</button>
                                        <img src = {`${hostname}/api/images/brands/${brand.linkBrand}`} alt = "H" />
                                        <span>{brand.nameBrand}</span>
                                        <button className = "update" onClick = {(e)=> handleUpdate(e, checked)}><img src = {editIcon} alt = "edit"/></button>

                                    </motion.li>
                                : 

                                null
                            ))
                        }</AnimatePresence>
                        ))
                    }
                </ul> 

            </div>
            
        </div>
    )


}

function HeaderCatalog(props) {
    const [searchBrand, setSearchBrand] = useState("")
    const allBrands = useSelector(brandsSelector)

    const handleSearchBrand = async (e) => {
        const value = e.target.value
        setSearchBrand(value)
        const searchString = value.trim().toLowerCase()
        const searchData = await allBrands.filter(brand => `${brand.nameBrand}`.toLowerCase().match(searchString))

        const dataFilter = await searchData.filter(brand => !props.checkboxesChecked.includes(brand.idBrand))

        props.setDataBrands(dataFilter)
    }

    return ( 
        <div className = "catalog__header">

            <div className = "catalog__header--filter">
                <input type = "text" onChange = {handleSearchBrand} value = {searchBrand} placeholder = "Rechercher une marque"/>
                <Link to = {`/CazaSticker2/brand/add`}>Ajouter</Link>
            </div>
        </div>
    )
}

function CatalogContent(props) {
    const allFlags = useSelector(flagsSelector)

    useEffect(() => {
        if(props.detectSelect) {
            const timer = setTimeout(() => {
                props.setDetectSelect(false)
            }, 650);
            return () => clearTimeout(timer);
        }
    })

    const handleOnChangeCheckedState = async(position) => {
        const checkboxSelected = props.dataBrands.filter((sticker, index) => index === position)
        const checkboxesCheckedCurrent = props.checkboxesChecked
        checkboxesCheckedCurrent.push(checkboxSelected[0].idBrand)
        props.setCheckboxesChecked(checkboxesCheckedCurrent)
        
        const dataFilter = await props.dataBrands.filter(brand => !props.checkboxesChecked.includes(brand.idBrand))
        props.setDataBrands(dataFilter)
        props.setDetectSelect(true)
    }
    return (
        <div className = "catalog__table">
            <table>
                <thead>
                    <tr>
                        <th>Logo de la marque</th>
                        <th>Nom de la marque</th>
                        <th>Drapeau</th>
                        <th>Nom du pays</th>
                    </tr>
                </thead>
                <tbody>

                    { Object.entries(props.dataBrands).length > 0 ? 
                        <AnimatePresence> {
                        props.dataBrands.map((brand, index) => (
                            <motion.tr 
                            exit = {{x : 1000, }}
                            animate = {{x : 0 }}
                            initial = {{x : 1000}}
                            transition = {{
                                type : "linear", 
                            }}
                            key = {brand.idBrand} 
                            onClick = {(e) => handleOnChangeCheckedState(index,e)}> 
                                <td><img src = {`${hostname}/api/images/brands/${brand.linkBrand}`} alt = {brand.nameBrand} /></td>
                                <td>{brand.nameBrand}</td>
                                
                                    {
                                        allFlags.find(flag => flag.idFlag === parseInt(brand.idFlag)) ? 
                                        <React.Fragment>
                                            <td><img src = {`${hostname}/api/images/flags/${allFlags.find(flag => flag.idFlag === parseInt(brand.idFlag)).linkFlag}`} alt = {allFlags.find(flag => flag.idFlag === parseInt(brand.idFlag)).nameFlag} /></td>
                                            <td>{allFlags.find(flag => flag.idFlag === parseInt(brand.idFlag)).nameFlag}</td>
                                        </React.Fragment>
                                        :   
                                            <React.Fragment>
                                            <td><img src = {noImage} alt = "Pas de drapeau lié"/></td>
                                            <td>Pas de drapeau lié</td>

                                            </React.Fragment>
                                    }
                                

                            </motion.tr>
                        ))
                                }</AnimatePresence>
                    :
                        <motion.tr 
                        exit = {{opacity : 1, transitionDelay : "800ms" }}
                        animate = {{opacity: 1, transitionDelay : "800ms" }}
                        initial = {{opacity : 0, transitionDelay : "800ms" }}
                        transition = {{
                         type : "linear", 
                         default : {duration : 0}
                         }}
                        id = 'catalog__table--noData'>
                            <td colSpan = {12}>Aucune marque</td>
                        </motion.tr>

                    }


                </tbody>
            </table>
        </div>
    )
}