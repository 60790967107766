import React, {useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import closeButton from '../../images/prev__button.png'
import openButton from '../../images/next__button.png' 
import { motion } from 'framer-motion'
import LogoCazasticker from '../../svg/logo-CazaSticker-animate.svg'


//<object type="image/svg+xml" data={LogoSVG}>svg-animation</object>



export default function Header(props) {
    const flag = useRef(null)
    const brand = useRef(null)
    const sticker = useRef(null)
    const [buttonHeader, setButtonHeader] = useState(true)
    const [disconnect, setDisconnect] = useState(false)


    const handleClickHeader = () => {
        //const headerTag = header.current
        const flagNav = flag.current
        const brandNav = brand.current
        const stickerNav = sticker.current

        setButtonHeader(!buttonHeader)
        props.setHeader(!props.header)
        //headerTag.classList.toggle('is-header-open')
        flagNav.classList.remove('is-flag-open')
        brandNav.classList.remove('is-brand-open')
        stickerNav.classList.remove('is-sticker-open')

    }

    const handleClickFlag = () => {
        const flagNav = flag.current
        const brandNav = brand.current
        const stickerNav = sticker.current

        flagNav.classList.toggle('is-flag-open')
        brandNav.classList.remove('is-brand-open')
        stickerNav.classList.remove('is-sticker-open')
    }

    const handleClickBrand = () => {
        const flagNav = flag.current
        const brandNav = brand.current
        const stickerNav = sticker.current

        brandNav.classList.toggle('is-brand-open')
        flagNav.classList.remove('is-flag-open')
        stickerNav.classList.remove('is-sticker-open')
    }

    const handleClickSticker = () => {
        const flagNav = flag.current
        const brandNav = brand.current
        const stickerNav = sticker.current

        stickerNav.classList.toggle('is-sticker-open')
        brandNav.classList.remove('is-brand-open')
        flagNav.classList.remove('is-flag-open')
        
    }

    const handleClickDisconnect = (e) => {
        e.preventDefault()
        localStorage.clear()
    }

    const handleWindowDisconnect = (e) => {
        e.preventDefault()
        setDisconnect(!disconnect)
    }

    return (
        <React.Fragment>
            {
                disconnect ? 
                <motion.div 
                className = "deletionConfirmation"
                exit = {{transform : "scale(0)"}}
                animate = {{transform : "scale(1)"}}
                initial = {{transform : "scale(0)"}}>
                    <div>
                        <p>Confirmer-vous la déconnexion ?</p>
                        <div>
                            <button onClick = {handleWindowDisconnect}>Annuler</button>
                            <button onClick = {handleClickDisconnect}>Confirmer</button>
                        </div>
                    </div>
                </motion.div>
                :
                null
            }
        
        <div className = 'header'>
            <div className = "header__button" onClick = {handleClickHeader}>{props.header ? <img src = {closeButton}  alt = "Fermer" />: <img src = {openButton}  alt = "Ouvrir" />}</div>
            <div className = {props.header ? "header__container is-header-open" : "header__container"}>
                <div className = "header__container__logo">
                    <object type="image/svg+xml" data={LogoCazasticker}>Loader</object>
                </div>
                <div className = "header__container__disconnect">
                    <button onClick = {handleWindowDisconnect}>Se déconnecter</button>
                </div>
                <div className = "header__container__navigation">
                    <div className = "header__container__navigation--element">
                        <Link to = {`/`}>Accueil</Link>
                    </div>

                    <div className = "header__container__navigation--element">
                        <p onClick = {handleClickFlag}>
                            Gestion des drapeaux
                            </p> 
                        <ul ref = {flag}>
                            <li><Link to = {`/flag/add`}>Ajouter</Link></li>
                            <li><Link to = {`/flag/catalog`}>Catalogue</Link></li>
                        </ul>
                    </div>
                    <div className = "header__container__navigation--element">
                        <p onClick = {handleClickBrand}>
                            Gestion des marques
                            </p> 
                        <ul ref = {brand}>
                            <li><Link to = {`/brand/add`}>Ajouter</Link></li>
                            <li><Link to = {`/brand/catalog`}>Catalogue</Link></li>
                        </ul>
                    </div>
                    <div className = "header__container__navigation--element">
                        <p onClick = {handleClickSticker}>Gestion des étiquettes</p> 
                        <ul ref = {sticker}>
                            <li><Link to = {`/sticker/add`}>Ajouter</Link></li>
                            <li><Link to = "/sticker/catalog">Catalogue</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            

        </div>
        </React.Fragment>
    )
}