
export const INITIALIZE_FLAGS_ACTION = "INITIALIZE_FLAGS_ACTION"
export const ADD_FLAGS_ACTION = "ADD_FLAGS_ACTION"
export const DELETE_FLAGS_ACTION = "DELETE_FLAGS_ACTION"
export const UPDATE_FLAGS_ACTION = "UPDATE_FLAGS_ACTION"

export default function flagsReducer(state = [], action) {
    switch (action.type) {
        case UPDATE_FLAGS_ACTION : 
            const index = state.findIndex(flag => flag.idFlag === action.payload.idFlag)
            const newArray = state
            newArray[index].nameFlag = action.payload.nameFlag
            newArray[index].linkFlag = action.payload.linkFlag
            return [...newArray]
        case DELETE_FLAGS_ACTION : 
            return state.filter(flag => !action.payload.includes(flag.idFlag))
        case ADD_FLAGS_ACTION : 
            return [{...action.payload}, ...state]
        case INITIALIZE_FLAGS_ACTION : 
            return [...action.payload]
        default : 
            return state
    }
}

