import { ADD_BRANDS_ACTION, DELETE_BRANDS_ACTION, INITIALIZE_BRANDS_ACTION, UPDATE_BRANDS_ACTION } from "../reducers/brandsReducer";



export const initializeBrandsAction = (brands) => ({
    type : INITIALIZE_BRANDS_ACTION, 
    payload : brands
})

export const addBrandsAction = (brand) => ({
    type : ADD_BRANDS_ACTION,
    payload : brand
})

export const updateBrandsAction = (brand) => ({
    type : UPDATE_BRANDS_ACTION,
    payload : brand
})


export const deleteBrandsAction = (checkboxesChecked) => ({
    type : DELETE_BRANDS_ACTION, 
    payload : checkboxesChecked
})