
export const INITIALIZE_BRANDS_ACTION = "INITIALIZE_BRANDS_ACTION"
export const ADD_BRANDS_ACTION = "ADD_BRANDS_ACTION"
export const DELETE_BRANDS_ACTION = "DELETE_BRANDS_ACTION"
export const UPDATE_BRANDS_ACTION = "UPDATE_BRANDS_ACTION"


export default function brandsReducer(state = [], action) {
    switch (action.type) {
        case UPDATE_BRANDS_ACTION : 
                const index = state.findIndex(brand => brand.idBrand === action.payload.idBrand)
                const newArray = state
                newArray[index].idFlag = action.payload.idFlag
                newArray[index].nameBrand = action.payload.nameBrand
                newArray[index].linkBrand = action.payload.linkBrand
            return [...newArray]
        case DELETE_BRANDS_ACTION : 
            return state.filter(brand => !action.payload.includes(brand.idBrand))
        case ADD_BRANDS_ACTION : 
            return [{...action.payload}, ...state]
        case INITIALIZE_BRANDS_ACTION : 
            return [...action.payload]
        default : 
            return state
    }
}