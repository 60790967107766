import React, {useState} from 'react'
import {hostname} from '../../config/utils'
//import Logo from '../../images/login.png'
import axios from 'axios'
import { motion } from 'framer-motion'
import LogoCazasticker from '../../svg/logo-CazaSticker-animate.svg'

export default function Login(props) {
    const [user, setUser] = useState({
        username : "", 
        password : ""
    })
    
    const handleChangeInput = (e) => {
        setUser(state => {
            return {
                ...state, 
                [e.target.name] : e.target.value
            }
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        props.setLoader(true)
        await axios.post(`${hostname}/api/login`, user)
        .then(res => {
            props.setLoader(false)
            if(res.status !== undefined) {
                props.setStatusRequest(res.data)
                props.setClientConnected(true)
                localStorage.setItem('token', res.data.token)
            } else {
                props.setStatusRequest({
                    status : false, 
                    message : "Votre identifiant ou votre mot de passe est incorrect."
                })
            }
        })
        .catch(err => {
            props.setLoader(false)
            props.setStatusRequest(err.response.data)
        })
    }

    
    return (
        <motion.div 
            exit = {{transform : "scale(0)"}}
            animate = {{transform : "scale(1)"}}
            initial = {{transform : "scale(0)"}}
            className = "login">
            
            <div className = "login--logo"><object type="image/svg+xml" data={LogoCazasticker}>Loader</object></div>
            <form className = "login__form" onSubmit = {handleSubmit}>
                <div className = "login__form--element">
                    <label htmlFor = "username">Identifiant</label>
                    <input type = "text" name = "username" value = {user.username} onChange = {handleChangeInput}/>
                </div>
                <div className = "login__form--element">
                    <label htmlFor = "username" >Mot de passe</label>
                    <input type = "password" name = "password" value = {user.password} onChange = {handleChangeInput}/>
                </div>
                <div className = "login__form--button"><input type = "submit" name = "submit" value = "Se connecter"/></div>
            </form>
        </motion.div>
    )
}