import React, {useState, useRef} from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import axios from 'axios'
import { conditionnalInputBrand, styleInputValidation } from '../../config/formProcessingVariables'
import { hostname } from '../../config/utils'
import { useSelector, useDispatch } from 'react-redux'
import { flagsSelector } from '../../store/selectors/flagsSelector'
import { addBrandsAction } from '../../store/actions/brandsActions'
import { Tutorials } from '../base/Dashboard'
import { tutorials } from '../../datas/tutorialsData'
import {Link} from 'react-router-dom'
import toolGoogleImage from "../../images/GoogleImage.jpg"
import toolPixelr from "../../images/Pixelr.jpg"

export default function AddBrand(props) {
    const [brand, setBrand] = useState({})
    const [brandValidation, setBrandValidation] = useState({})
    const [componentLoad, setComponentLoad] = useState(false)
    const [tutorialBox, setTutorialBox] = useState({})

    if(props.header && !componentLoad) {
        props.setHeader(false)
        setComponentLoad(true)
    }
    const dispatch = useDispatch()

    const [filePreview, setFilePreview] = useState('')

    const handleChangeInput = (e) => {
        if(e.target.name === "linkBrand") {
            const fileUploaded = e.target.files[0]
            if (fileUploaded) {
                setBrand(state => {
                    return {
                        ...state, 
                        [e.target.name] : fileUploaded
                    }
                })
                setFilePreview(URL.createObjectURL(fileUploaded))
            } else {
                setBrand(state => {
                    return {
                        ...state, 
                        [e.target.name] : ''
                    }
                })
                setFilePreview("")
            }


            setBrandValidation(state => {
                return {
                    ...state,
                    [e.target.name] : conditionnalInputBrand(e)
            }})
            


        } else {
            const value = e.target.value
            setBrand(state => {
                return {
                    ...state, 
                    [e.target.name] : value
                }
            })
    
            setBrandValidation(state => {
                return {
                    ...state,
                    [e.target.name] : conditionnalInputBrand(e)
            }})
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        props.setLoader(true)
        if(brandValidation.nameBrand && brandValidation.idFlag && brandValidation.linkBrand) {
            const formContainer = new FormData()
            formContainer.append('nameBrand', brand.nameBrand)
            formContainer.append('flagBrand', brand.idFlag)
            formContainer.append('file', brand.linkBrand)

            axios.post(`${hostname}/api/brands/add`, formContainer, {
                headers : {
                    "authorization" : localStorage.getItem('token')
                }
            })
            .then(async (res) => { 
                dispatch(addBrandsAction(res.data.addedBrand))
                props.setLoader(false)
                props.setStatusRequest(res.data)
            })
            .catch(err => {
                props.setLoader(false)
                if(err.response === undefined) {
                    props.setStatusRequest({
                        status : false, 
                        message : "Vous avez été déconnécté du serveur, veuillez vous reconnecter."
                    })
                } else {
                    props.setStatusRequest(err.response.data)
                }
            })

        } else {
            props.setLoader(false)
            const validationRequest = [
                {nameInput : 'nameBrand', message : "Veuillez entrer le nom de la marque"}, 
                {nameInput : 'idFlag', message : "Veuillez choisir un pays"}, 
                {nameInput : 'linkBrand', message : "Veuillez télécharger la marque"} 
            ]

            validationRequest.map(validation => {
                if(!brandValidation[validation.nameInput] || brandValidation[validation.nameInput] === null) {
                    setBrandValidation(state => {
                        return {
                            ...state, 
                            [validation.nameInput] : false
                        }
                    })
                }

                const falseValidation = Object.values(brandValidation).filter(validation => validation === false).length
                if(falseValidation > 1) {
                    return props.setStatusRequest({
                        status : false, 
                        message : "Votre demande est incomplète"
                    })
                } else {
                    if (!brandValidation[validation.nameInput]) {
                        props.setStatusRequest({
                            status : false, 
                            message : validation.message
                        })
                    }
                }
            })

        }
        
    }
    return (
        <React.Fragment>
            <AnimatePresence exitBeforeEnter initial = {false}>
            {
            Object.entries(tutorialBox).length > 0  ? 
            <Tutorials
                setTutorialBox = {setTutorialBox}
                tutorialBox = {tutorialBox}
            />
            :

            null
        }
        </AnimatePresence>

        <motion.form 
        exit = {{transform : "scale(0)"}}
        animate = {{transform : "scale(1)"}}
        initial = {{transform : "scale(0)"}}
        className = "add" 
        onSubmit = {handleSubmit}
    >
        <div className = "catalog__header--title">Ajouter une marque</div>
        <FlagBrand
            allFlags = {props.allFlags}
            brand = {brand}
            brandValidation = {brandValidation}
            handleChangeInput = {handleChangeInput}
        />
        
        <NameBrand 
            brand = {brand}
            brandValidation = {brandValidation}
            handleChangeInput = {handleChangeInput}
        />

        <FileBrand 
            brand = {brand}
            brandValidation = {brandValidation}
            handleChangeInput = {handleChangeInput}
            filePreview = {filePreview}
            setTutorialBox = {setTutorialBox}
            tutorialBox = {tutorialBox}
        />
    <div className = "add__button">
        <Link to = {`/CazaSticker2/brand/catalog`}>Catalogue</Link>
        <input type = "submit" value = "Ajouter" /></div>
    </motion.form>
    </React.Fragment>
    )
}

export function FlagBrand(props) {
    const allFlags = useSelector(flagsSelector)
    return (
        <div className = "add__content">
        <label htmlFor = "idFlag">À quel pays correspond votre marque ?</label>

        <select value = {props.brand.idFlag} name = "idFlag" onChange = {props.handleChangeInput} style = {{border : styleInputValidation(props.brandValidation, 'idFlag')}} >
            <option value = "default">Choisissez un drapeau</option>
            {
                allFlags.length > 0 ? 
                    allFlags.map((element) => (
                        <option key = {element.idFlag} value = {element.idFlag}>{element.nameFlag}</option>
                    ))
                :
                    null
            }
        </select>
        </div>
    )
}

export function NameBrand(props) {
    return (
        <div className = "add__content">
            <label htmlFor = "nameBrand">Quel est le nom de la marque</label>
            <input type = "text" name = "nameBrand" onChange = {props.handleChangeInput} value = {props.brand.nameBrand} style = {{border : styleInputValidation(props.brandValidation, 'nameBrand')}} maxLength="20"/>
        </div>
    )
}

export function FileBrand(props) {
    const fileBrandRef = useRef(null)

    const handleClickFileBrand = (e) => {
        e.preventDefault()
        fileBrandRef.current.click()
    }

    const handleClickTutorial = (e) => {
        e.preventDefault()
        props.setTutorialBox(tutorials[2])
    }

    return(
        <div className = "add__content">
        <label htmlFor = "fileFlag">Uploader l'image du logo : </label>
        <p><span onClick = {handleClickTutorial}>Suivez notre tuto vidéo pour réaliser l'image</span>.<br/> Le fichier doit faire <strong>290px par 190px</strong> et avoir <strong>l'extension .jpg</strong>.</p>
        {props.filePreview !== '' ? 
            <div className = "add__content--preview">
                <img src = {props.filePreview} alt = "FilePreview" />
            </div>
        : 
            null
        }
        <div className = "add__content__tools">
            <div>
                <a href = "https://www.google.fr/imghp?hl=fr" target = "_blank">
                    <img src = {toolGoogleImage} alt = "Google Image"/>
                </a>
            </div>
            <div>
                <a href = "https://pixlr.com/fr/x/" target = "_blank">
                    <img src = {toolPixelr} alt = "Pixlr X"/>
                </a>
            </div>
        </div>
        <button onClick = {handleClickFileBrand} style = {{border : styleInputValidation(props.brandValidation, 'linkBrand')}}>Choisir un fichier</button>
        <input type = "file" accept="image/png, image/jpeg, image/jpg" style = {{display : 'none'}} name = "linkBrand" onChange = {props.handleChangeInput} ref = {fileBrandRef}/>
        </div>
    )
}