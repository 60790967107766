import { ADD_FLAGS_ACTION, DELETE_FLAGS_ACTION, INITIALIZE_FLAGS_ACTION, UPDATE_FLAGS_ACTION } from "../reducers/flagsReducer"


export const initializeFlagsAction = (flags) => ({
    type : INITIALIZE_FLAGS_ACTION, 
    payload : flags
})

export const addFlagsAction = (flag) => ({
    type : ADD_FLAGS_ACTION, 
    payload : flag
})

export const updateFlagsAction = (flag) => ({
    type : UPDATE_FLAGS_ACTION, 
    payload : flag
})

export const deleteFlagsAction = (checkboxesChecked) => ({
    type : DELETE_FLAGS_ACTION, 
    payload : checkboxesChecked
})