import React, {useState, useRef} from 'react'
import {motion, AnimatePresence} from 'framer-motion'
import axios from 'axios'
import {conditionnalInputFlag, styleInputValidation} from '../../config/formProcessingVariables'
import {hostname} from '../../config/utils'
import { useDispatch } from 'react-redux'
import { addFlagsAction } from '../../store/actions/flagsActions'
import { Tutorials } from '../base/Dashboard'
import { tutorials } from '../../datas/tutorialsData'
import {Link} from 'react-router-dom'
import toolGoogleImage from "../../images/GoogleImage.jpg"
import toolPixelr from "../../images/Pixelr.jpg"


export default function AddFlag(props) {
    // State for data input
    const [flag, setFlag] = useState({})
    const [flagValidation, setFlagValidation] = useState({})
    const [componentLoad, setComponentLoad] = useState(false)
    const [tutorialBox, setTutorialBox] = useState({})


    if(props.header && !componentLoad) {
        props.setHeader(false)
        setComponentLoad(true)
    }

    //REDUX 
    const dispatch = useDispatch()

    //State for preview
    const [filePreview, setFilePreview] = useState('')

    const handleChangeInput = (e) => {
        if(e.target.name === "linkFlag") {
            const fileUploaded = e.target.files[0] 
            if(fileUploaded) {
                setFlag(state => {
                    return {
                        ...state, 
                        [e.target.name] : fileUploaded
                    }
                })
                setFilePreview(URL.createObjectURL(fileUploaded))
            } else {
                setFlag(state => {
                    return {
                        ...state, 
                        [e.target.name] : ''
                    }
                })
                setFilePreview("")
            }
            setFlagValidation(state => {
                return {
                    ...state, 
                    [e.target.name] : conditionnalInputFlag(e)
                }
            })
        } else {
            const value = e.target.value
            setFlag(state => {
                return {
                    ...state, 
                    [e.target.name] : value
                }
            })
    
            setFlagValidation(state => {
                return {
                    ...state,
                    [e.target.name] : conditionnalInputFlag(e)
            }})
        }
    }

    //console.log(`State : ${JSON.stringify(flag)}`)
    const handleSubmit = async (e) => {
        e.preventDefault()
        props.setLoader(true)
        if(flagValidation.nameFlag && flagValidation.linkFlag) {
            var formContainer = new FormData()
            formContainer.append('nameFlag', flag.nameFlag)
            formContainer.append('file', flag.linkFlag)
            await axios.post(`${hostname}/api/flags/add`, formContainer, {
                headers : {
                    "authorization" : localStorage.getItem('token')
                }
            })
            .then(res => { // then print response status
                dispatch(addFlagsAction(res.data.flagAdd))
                props.setLoader(false)
                props.setStatusRequest(res.data)
            })
            .catch(err => {
                props.setLoader(false)
                if(err.response === undefined) {
                    props.setStatusRequest({
                        status : false, 
                        message : "Vous avez été déconnécté du serveur, veuillez vous reconnecter."
                    })
                } else {
                    props.setStatusRequest(err.response.data)
                }
            })

        } else {
            props.setLoader(false)
            const validationRequest = [
                {nameInput : 'nameFlag', message : "Veuillez entrer le nom du pays"}, 
                {nameInput : 'linkFlag', message : "Veuillez télécharger un drapeau"}
            ]

            validationRequest.map(validation => {
                if(!flagValidation[validation.nameInput] || flagValidation[validation.nameInput] === null) {
                    setFlagValidation(state => {
                        return {
                            ...state, 
                            [validation.nameInput] : false
                        }
                    })
                }

                if(!flagValidation.nameFlag && !flagValidation.linkFlag) {
                    props.setStatusRequest({
                        status : false, 
                        message : "Votre demande est incomplète"
                    })
                } else {
                    if (!flagValidation[validation.nameInput]) {
                        props.setStatusRequest({
                            status : false, 
                            message : validation.message
                        })
                    }
                }
            })
        }
    }
    return (
        <React.Fragment>
            <AnimatePresence exitBeforeEnter initial = {false}>
            {
            Object.entries(tutorialBox).length > 0  ? 
            <Tutorials
                setTutorialBox = {setTutorialBox}
                tutorialBox = {tutorialBox}
            />
            
            :

            null
            }
            </AnimatePresence>
        <motion.form 
        exit = {{transform : "scale(0)"}}
        animate = {{transform : "scale(1)"}}
        initial = {{transform : "scale(0)"}}
        className = "add" 
        onSubmit = {handleSubmit}
        encType="multipart/form-data"
    >
        <div className = "catalog__header--title">Ajouter un drapeau</div>
        <NameFlag
            flag = {flag}
            flagValidation = {flagValidation}
            handleChangeInput = {handleChangeInput}
        />

        <FileFlag 
            flag = {flag}
            flagValidation = {flagValidation}
            handleChangeInput = {handleChangeInput}
            filePreview = {filePreview}
            tutorialBox = {tutorialBox}
            setTutorialBox = {setTutorialBox}
        />

        
        <div className = "add__button">
            <Link to = {`/CazaSticker2/flag/catalog`}>Catalogue</Link>
            <input type = "submit" value = "Ajouter" />
        </div>

    </motion.form>
    </React.Fragment>
    )
}

export function NameFlag(props) {
    return (
        <div className = "add__content">
            <label htmlFor = "nameFlag">Quel est le nom du pays ?</label>
            <input type = "text" name = "nameFlag" onChange = {props.handleChangeInput}  value = {props.flag.nameFlag} style = {{border : styleInputValidation(props.flagValidation, 'nameFlag')}} maxLength="20"/>
        </div>
    )
}

export function FileFlag(props) {
    const fileFlagRef = useRef(null)

    const handleClickFileFlag = (e) => {
        e.preventDefault()
        fileFlagRef.current.click()
    }

    const handleClickTutorial = (e) => {
        e.preventDefault()
        props.setTutorialBox(tutorials[1])

    }
    return (
        <div className = "add__content">
        <label htmlFor = "linkFlag">Uploader l'image du drapeau : </label>
        <p><span onClick = {handleClickTutorial}>Suivez notre tuto vidéo pour réaliser l'image.</span><br/> Le fichier doit faire <strong>60px par 40px</strong> et avoir <strong>l'extension .jpg</strong>.</p> 
        {props.filePreview !== '' ? 
            <div className = "add__content--preview">
                <img src = {props.filePreview} alt = "FilePreview" />
            </div>
        : 
            null
        }
                <div className = "add__content__tools">
            <div>
                <a href = "https://www.google.fr/imghp?hl=fr" target = "_blank">
                    <img src = {toolGoogleImage} alt = "Google Image"/>
                </a>
            </div>
            <div>
                <a href = "https://pixlr.com/fr/x/" target = "_blank">
                    <img src = {toolPixelr} alt = "Pixlr X"/>
                </a>
            </div>
        </div>
        <button onClick = {handleClickFileFlag} style = {{border : styleInputValidation(props.flagValidation, 'linkFlag')}}>Choisir un fichier</button>
        
        <input type = "file" accept="image/png, image/jpeg, image/jpg" name = "linkFlag" style = {{display : 'none'}} onChange = {props.handleChangeInput} ref = {fileFlagRef}/>
        </div>
    )
}

