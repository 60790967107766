/*
Pour ces fonctions, il faut absolument : 
- La valeur courante de l'input (e.target.Value)
- Un state de validation ([namevalidation, setNameValidation])
*/ 



// A placer sur la balise
export const checkInputValidation = (inputValidation) => inputValidation === null ? "none" : inputValidation ? "1.5px solid #1df301" : "1.5px solid red"


// A diffuser dans les handleChange selon le type de l'input
export const conditionnalInputNumberValidation = (maxLength, value, setNameValidation) => {
    if(value.length === 0) {
        setNameValidation(null)
    } else if (value.length > 0) {
        if (value.length > maxLength) {
            setNameValidation(false)
        } else {
            setNameValidation(true)
        }

    }
}

export const conditionnalInputSelectValidation = (value, setNameValidation) => {
    if(value === "default") {
        setNameValidation(false)
    } else {
        setNameValidation(true)
    }
}

export const conditionnalInputTextValidation = (maxLength, value, setNameValidation) => {  
    if(value.length === 0) {
        setNameValidation(null)
    } else if (value.length > 0) {
        if (value.length > maxLength) {
            setNameValidation(false)
        } else {
            setNameValidation(true)
        }
    }
}

//Test pour mettre en rouge tout ce qui n'est pas à true dans onSubmit
export const triggerInputErrorValidation = (validations) => {
    for (var stateValidation of validations) {
        if(!stateValidation[0]) {
            stateValidation[1](false)
        }
    }
} 

export const triggerInputSuccessValidation = (validations) => {
    for (var stateValidation of validations) {
        if(!stateValidation[0]) {
            stateValidation[1](true)
        }
    }
} 


//Fonctions à garder 
export const styleInputValidation = (arrayValidation, nameInput) => {
    if (!Object.keys(arrayValidation).includes(nameInput) || arrayValidation[nameInput] === null) {
        return "none"
    } else {
        if (arrayValidation[nameInput]) {
            return "2px solid #1df301"
        } else {
            return "2px solid red"
        }
    }
}


export const conditionnalInputSticker = (e) => {
    if (e.target.name === "price1" || e.target.name === "capacity1" || e.target.name === "pg1" || e.target.name === "price2" || e.target.name === "capacity2" || e.target.name === "pg2") {
        if(e.target.value === "") {
            return null
        }
        var pattern = e.target.pattern != null ? new RegExp(e.target.pattern) : null
        if(pattern == null || pattern.test(e.target.value)) {
            return true
        } else {
            return null
        }
    }

    if(e.target.type === "select-one") {
        if(e.target.value === "default") {
            return false
        } else {
            return true
        }
    } else if (e.target.type === "text" ) {
        if(e.target.name === "title1" || e.target.name === "title2") {
            if(e.target.value.length > 15) {
                return false
            } else if (e.target.value === "") {
                return null
            } else {
                return true
            }
        } else if (e.target.name === "taste1" || e.target.name === "taste2" || e.target.name === "taste3"){
            if(e.target.value.length > 28) {
                return false
            } else if (e.target.value === "") {
                return null
            } else {
                return true
            }
        } else {
            if(e.target.value.length > 4) {
                return false
            } else if (!e.target.value || e.target.value === "") {
                return null
            } else {
                return true
            }
        }
    } else if (e.target.type === "number") {
        if(e.target.length > 10) {
            return false 
        } else if (e.target.value === "") {
            return null
        } else {
            return true
        }
    }
}


export const conditionnalInputBrand = (e) => {
    if(e.target.type === "select-one") {
        if(e.target.value === "default") {
            return false
        } else {
            return true
        }
    } else if (e.target.type === "text") {
        if(e.target.value.length > 30) {
            return false
        } else if (e.target.value === "" ) {
            return null
        } else {
            return true
        }
    } else if (e.target.type === "file") {
        if(e.target.files[0]) {
            return true
        } else {
            return false
        }
    }
}

export const conditionnalInputFlag = (e) => {
    if(e.target.type === "text") {
        if(e.target.value.length > 30) {
            return false
        } else if (e.target.value === "") {
            return null
        } else {
            return true
        }
    } else if (e.target.type === "file") {
        if(e.target.files[0]) {
            return true
        } else {
            return false
        }
    }
}

export function handleChangeStateSticker(e, setState) {
    const value = e.target.value

    if(e.target.name === "price1" || e.target.name === "price2") {
        if(value.includes(',')) {
            const newValue = value.replace(',', '.')
            return setState(state => {
                return {
                    ...state, 
                    [e.target.name] : newValue
                }
            })
        }
    } 
    
    var pattern = e.target.pattern != null ? new RegExp(e.target.pattern) : null

    if(pattern == null || pattern.test(e.target.value)) {
        setState(state => {
            return {
                ...state, 
                [e.target.name] : value
            }
        })
    } 
}