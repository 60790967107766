import React, {useState, useRef} from 'react'
import { tutorials } from '../../datas/tutorialsData'
import { hostname } from "../../config/utils"
import { motion , AnimatePresence} from 'framer-motion'
import {ReactComponent as CloseSVG} from '../../svg/icon-close.svg'
import {ReactComponent as ShowHideSVG} from '../../svg/showHide-icon.svg'
import {useSelector} from 'react-redux'
import { stickersSelector } from '../../store/selectors/stickersSelector'
import { brandsSelector } from '../../store/selectors/brandsSelector'
import noImage from '../../images/noImage.png'
import {Link} from 'react-router-dom'
export default function Dashboard(props) {

    const allStickers = useSelector(stickersSelector)
    const allBrands = useSelector(brandsSelector)
    const [tutorialBox, setTutorialBox] = useState({})


    const handleClickViewer = (e, position) => {
        e.preventDefault()
        setTutorialBox(tutorials[position])
    }
    return ( 
        <React.Fragment>
        <AnimatePresence exitBeforeEnter initial = {false}>
        {
            Object.entries(tutorialBox).length > 0  ? 
            <Tutorials 
                setTutorialBox = {setTutorialBox}
                tutorialBox = {tutorialBox}
            />
            :

            null
        }
        </AnimatePresence>
        <motion.div 
            className = "dashboard"
            exit = {{transform : "scale(0)"}}
            animate = {{transform : "scale(1)"}}
            initial = {{transform : "scale(0)"}}
        >
            <span className = "dashboard__title">Hey, Salut à toi !!</span>
            <div className = "dashboard__tutorials">
            <div className = "dashboard__tutorials--title">Les tutos</div>
                <div className = "dashboard__tutorials--buttons">
                    {
                        tutorials.map((tuto, position) => (
                            <button key = {position} onClick = {(e) => handleClickViewer(e, position)}>{tuto.name}</button>
                        ))
                    }
                </div>
            </div>
            <div className = "dashboard__lastStickers">
                <p>Dernières étiquettes ajoutées</p>
                <div>
                <table>
                        <thead>
                        <tr>
                            <th>Liquide</th>
                            <th>Marque</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            allStickers.length > 0 && allBrands.length > 0 ? 
                                allStickers.slice(0,30).map((sticker, position) => (
                                        <tr key = {`${sticker.idLiquid}-${sticker.title1}`}>
                                            {
                                                sticker.title2 === null ? 
                                                    <td>{`${sticker.title1}`}</td>
                                                : 
                                                    <td>{`${sticker.title1} ${sticker.title2}`}</td>
                                            }
                                            <td>
                                                {
                                                    allBrands.find(brand => brand.idBrand === parseInt(sticker.idBrand)) ? 
                                                        <img src = {`${hostname}/api/images/brands/${allBrands.find(brand => brand.idBrand === parseInt(sticker.idBrand)).linkBrand}`} alt = {allBrands.find(brand => brand.idBrand === parseInt(sticker.idBrand)).nameBrand} />
                                                    : 
                                                        <img src = {noImage} alt = "Pas de marque liée"/>
                                                }
                                            </td>
                                        </tr>
                                ))
                            : 
                            <tr className = "noData">
                                <td colSpan = {12}>Aucune étiquette</td>
                            </tr>
                        }

                    </tbody>
                </table>
                </div>
                <div className="dashboard__lastStickers__goCatalogSticker">
                    <Link to = "/CazaSticker2/sticker/catalog"> Accès au catalogue </Link>
                </div>
            </div>
        </motion.div>
        </React.Fragment>
    )
}



export function Tutorials(props) {
    const summaryRef = useRef(null)
    const hideShowRef = useRef(null)
    const handleClose = (e) => {
        e.preventDefault()
        props.setTutorialBox({})
    }

    const handleClickShowHide = () => {
        var summaryCurrent = summaryRef.current
        var hideShowCurrent = hideShowRef.current
        summaryCurrent.classList.toggle('is-summary-open')
        hideShowCurrent.classList.toggle('show')
    }

    return (
        <motion.div 
            exit = {{y : -2000}}
            animate = {{y : 0}}
            initial = {{y : -2000}}
            transition = {{
                type : 'linear'
            }}
            className = "tutorials"
        >
            
            <div className = "catalog__update__close"><CloseSVG onClick = {handleClose}/></div>
            <div className = "tutorials__video">
                <video 
                    controls 
                    currentTime={11.3}
                    src={props.tutorialBox.video}
                />
            </div>
            <div className = "tutorials__summary">
                <div id = "showHide" className = "show" onClick = {handleClickShowHide} ref = {hideShowRef}><ShowHideSVG/></div>
                <div className = "tutorials__summary__container is-summary-open" ref = {summaryRef}>
                    <div className = "tutorials__summary__container--title">
                        {props.tutorialBox.name}
                    </div>
                    <div className = "tutorials__summary__container--content">
                        {props.tutorialBox.content}
                    </div>
                </div>

            </div>
        </motion.div>
    )
}
