import previewVideoHorizon from "../images/PreviewHorizon.jpg"
import previewVideoFlag from "../images/PreviewDrapeau.jpg"
import previewVideoBrand from "../images/PreviewMarque.jpg"
import previewVideoSticker from "../images/PreviewEtiquette.jpg"
import videoHorizon from "../videos/TourHorizon.mp4"
import videoFlag from "../videos/CreerDrapeau.mp4"
import videoBrand from "../videos/CreerMarque.mp4"
import videoSticker from "../videos/CreerEtiquette.mp4"


const SummaryPreview = () => {
    return (
        <ul>
            <li>
                Découvrez les possibilités de création d'étiquettes avec <span>Cazasticker</span>.
            </li>
            <li>
                Il existe <span>trois sections</span> pour réaliser les étiquettes :
            </li>
            <li>
                - <span>Les drapeaux</span> (qui sont liés aux logos des marques).
            </li>
            <li>
                - <span>Les marques</span> (qui sont liés aux drapeaux et aux étiquettes finales).
            </li>
            <li>
                - <span>Les étiquettes</span> (qui intègrent le drapeau et la marque associée à celles-ci).
            </li>
            <li>
                Dans le <span>catalogue des étiquettes</span>, vous pourrez vérifier l'existence d'une étiquette, et aurez la possibilité de <span>sélectionner</span>, <span>supprimer</span>, <span>modifier</span> et <span>imprimer</span> les étiquettes de votre choix.
            </li>
            <li>
            <u><span>PS</span> : Pensez à regarder la vidéo pour <span>plus d'informations</span>.</u>
            </li>
        </ul>
    )
}



const SummaryCreateFlag = () => {
    return (
        <ul>
        <li><span>1 / </span>Avant de créer le drapeau, se rendre sur <span>le catalogue des drapeaux</span> pour vérifier son existence ou non. Le cas échéant, cliquez sur le bouton "<span>Ajouter</span>" pour le créer.</li>
        <li><span>2 / </span>Afin de trouver l'image d'un drapeau adéquate, rendez-vous sur <a rel="noreferrer" href = "https://www.google.fr/imghp?hl=fr"  target="_blank">Google Images</a>. Tapez le mot "<span>flag</span>" suivi du <span>nom du pays</span>.</li>
        <li><span> 3 / </span>Une fois l'image trouvée, cliquez droit et "<span>enregistrer l'image sous...</span>". Vérifiez l'extension de celle-ci qui doit être en <span>.jpg</span> ou <span>.png</span></li>
        <li><span>4 / </span>Rendez-vous ensuite sur <a href="https://pixlr.com/fr/x/" rel="noreferrer" target="_blank">PixlR</a>, donnez un <span>titre</span> et calibrez les dimensions à <span>60px de largeur</span> et <span>40px de hauteur</span>. Sélectionnez "<span>Arrière-plan</span>" et choisissez la couleur <span>blanche</span>. Cliquez sur "<span>Créer</span>".</li>
        <li><span>5 / </span>Dans la barre d'outils à gauche, cliquez sur "<span>Ajouter une image</span>" et sélectionnez l'image du drapeau téléchargée précédement. <span>Ajustez sa taille</span> grâce aux poignées entourant l'image, de manière à <span>remplir l'espace de travail</span>. <span>Si celle-ci ne remplie pas correctement l'ensemble de l'espace de travail</span>, une déformation est possible en cliquant sur le bouton "<span>organiser et styler</span>". Cliquez ensuite sur "<span>transformer</span>" puis sur le <span>cadenas</span>. Déformez alors l'image dans l'espace de travail. N'oubliez pas de <span>recentrer celle-ci</span> au besoin.</li>
        <li><span>6 / </span>Cliquez sur "<span>Enregistrer</span>" et vérifier les informations du titre et de la <span>taille</span>. Mettre l'extension du fichier en "<span>.jpg</span>" puis téléchargez-le.</li>
        <li><span>7 / </span>De retour sur la page d'<span>ajout d'un drapeau</span> de CazaSticker, rentrez le <span>nom du pays</span> puis <span>uploadez le fichier</span> que vous venez de créer.</li>
        <li><span>8 / </span><strong>Félicitations</strong>, le drapeau est maintenant utilisable. Vous pouvez vérifier ses informations dans le catalogue des drapeaux et le <span>modifier</span> si besoin.</li>
        <li>
        <u><span>PS</span> : Pensez à regarder la vidéo pour <span>plus d'informations</span>.</u>
            </li>
        </ul>
    )
}

const SummaryCreateLogo = () => {
    return (
        <ul>
        <li><span>1 / </span>Avant de créer la marque, se rendre sur <span>le catalogue des marques</span> pour vérifier son existence ou non. Le cas échéant, cliquez sur le bouton "<span>Ajouter</span>" pour la créer.</li>
        <li><span>2 / </span>Afin de trouver l'image de la marque adéquate, rendez-vous sur <a rel="noreferrer" href = "https://www.google.fr/imghp?hl=fr"  target="_blank">Google Images</a>. Tapez le mot "<span>logo</span>" suivi du <span>nom de la marque</span>.</li>
        <li><span> 3 / </span>Une fois l'image trouvée, cliquez droit et "<span>enregistrer l'image sous...</span>". Vérifiez l'extension de celle-ci qui doit être en <span>.jpg</span> ou <span>.png</span></li>
        <li><span>4 / </span>Rendez-vous ensuite sur <a href="https://pixlr.com/fr/x/" rel="noreferrer" target="_blank">PixlR</a>, donnez un <span>titre</span> et calibrez les dimensions à <span>290px de largeur</span> et <span>190px de hauteur</span>. Sélectionnez "<span>Arrière-plan</span>" et choisissez la couleur <span>blanche</span>. Cliquez sur "<span>Créer</span>".</li>
        <li><span>5 / </span>Dans la barre d'outils à gauche, cliquez sur "<span>Ajouter une image</span>" et sélectionnez l'image de la marque téléchargée précédement. <span>Ajustez sa taille</span> grâce aux poignées entourant l'image, de manière à <span>remplir l'espace de travail soit sur sa hauteur soit sur sa largeur</span>. N'oubliez pas de <span>recentrer celle-ci</span> au besoin.</li>
        <li><span>6 / </span>Cliquez sur "<span>Enregistrer</span>" et vérifier les informations du titre et de la <span>taille</span>. Mettre l'extension du fichier en "<span>.jpg</span>" puis téléchargez-la.</li>
        <li><span>7 / </span>De retour sur la page d'<span>ajout d'une marque</span> de CazaSticker, <span>sélectionnez le pays correspondant</span> et rentrez le <span>nom de la marque</span>. Enfin, <span>uploadez le fichier</span> que vous venez de créer.</li>
        <li><span>8 / </span><strong>Félicitations</strong>, la marque est maintenant utilisable. Vous pouvez vérifier ses informations dans le catalogue des marques et la <span>modifier</span> si besoin.</li>
        <li>
        <u><span>PS</span> : Pensez à regarder la vidéo pour <span>plus d'informations</span>.</u>
            </li>
        </ul>
    )
}


const SummaryCreateSticker = () => {
    return (
        <ul>
        <li><span>1 / </span>Avant de créer l'étiquette, se rendre sur <span>le catalogue des étiquettes</span> pour vérifier son existence ou non. Vous pouvez faciliter la recherche en <span>filtrant par le nom de la marque</span>. Si l'étiquette n'existe pas, cliquez sur le bouton "<span>Ajouter</span>" pour la créer.</li>
        <li><span>2 / </span>Choisissez la <span>marque correspondante</span> puis entrer le <span>nom du liquide</span>. Il y a <span>deux lignes</span> pour écrire le titre car <span>chaque ligne à un nombre limité de caractères</span>.</li>
        <li><span>3 / </span>Les trois lignes suivantes, pour le goût du liquide, sont également limitées en caractères. À vous de <span>réussir à faire rentrer toutes les informations</span> ;).</li>
        <li><span>4 / </span>Pour le <span>prix</span>, utiliser le "<span>.</span>" à la place de la "<span>,</span>" et n'oubliez pas de mettre le "<span>0</span>" à la fin du prix (5.5<span>0</span>). Pour le "<span>Type</span>", sélectionnez la bonne information. Le "<span>Pourcentage</span>" sert à donner la proportion de <span>PG</span> .Le <span>VG</span> est calculé automatiquement. Vous pouvez <span>ajouter une seconde ligne</span> pour un ZHC en cliquant sur <span>le plus</span>.</li>
        <li><span>5 / </span>Une fois que tout est ok, cliquez sur le bouton "<span>Vailder</span>" pour enregistrer l'étiquette. Cliquez ensuite sur "<span>Catalogue</span>" pour vérifier.</li>
        <li><span>6 / </span><strong>Félicitations</strong>, l'étiquette a été créée. Elle pourra être "<span>supprimée</span>", "<span>modifiée</span>" ou "<span>imprimée</span>" comme bon vous semble.</li>
        <li>
        <u><span>PS</span> : Pensez à regarder la vidéo pour <span>plus d'informations</span>.</u>
            </li>
        </ul>
    )
}

export const tutorials = [
    {
        name : "Tour d'horizon", 
        preview : previewVideoHorizon,
        video : videoHorizon,
        content : <SummaryPreview/>
    }, 
    {
        name : "Créer un drapeau", 
        preview : previewVideoFlag,
        video : videoFlag, 
        content : <SummaryCreateFlag/>
    },
    {
        name : "Créer le logo d'une marque", 
        preview : previewVideoBrand,
        video : videoBrand, 
        content : <SummaryCreateLogo/>
    },
    {
        name : "Créer une étiquette", 
        preview : previewVideoSticker,
        video : videoSticker, 
        content : <SummaryCreateSticker/>
    },
]



