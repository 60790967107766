import React, {useEffect} from 'react'
import { motion } from "framer-motion";
import FalseIMG from '../../svg/stop.svg'
import TrueIMG from '../../svg/valid.svg'
//import FalseIMG from '../../images/stop.png'
//import TrueIMG from '../../images/valid.png'
import { AnimatePresence } from 'framer-motion';

export default function StatusRequest({statusRequest, setStatusRequest}) {
    useEffect(() => {
        const timer = setTimeout(() => {
            setStatusRequest({})
        }, 3000);
        return () => clearTimeout(timer);
      });
    
    const classNameStatus = statusRequest.status ? "statusRequest statusRequest__success" : "statusRequest statusRequest__failed"
    return ( 
        <AnimatePresence exitBeforeEnter initial = {false}>
            {Object.entries(statusRequest).length !== 0 ? 
                <motion.div 
                exit = {{x : 1500}}
                animate = {{x : 0}}
                initial = {{x : 1500}}
                transition = {{
                    type : 'linear', 
                }}
                className = {classNameStatus}>
                    <div>
                        <div className = "imageStatus">
                            {
                                statusRequest.status ? 
                                    <motion.object 
                                    exit = {{x : 0}}
                                    animate = {{x : 0}}
                                    initial = {{x : 0}}
                                    transition = {{
                                        type : 'linear', 
                                        delay : 1
                                    }}
                                    type="image/svg+xml" data={TrueIMG}>Loader</motion.object>

                                :
                                    <object type="image/svg+xml" data={FalseIMG}>Loader</object>
                            }
                        </div>
                        <p>{statusRequest.message}</p>
                    </div> 
                </motion.div>
            :
            null
            }
        </AnimatePresence>
    )
}
