import { ADD_STICKERS_ACTION, DELETE_STICKERS_ACTION, INITIALIZE_STICKERS_ACTION, UPDATE_STICKERS_ACTION } from "../reducers/stickersReducer"

export const initializeStickersAction = (stickers) => ({
    type : INITIALIZE_STICKERS_ACTION, 
    payload : stickers
})

export const addStickersAction = (sticker) => ({
    type : ADD_STICKERS_ACTION, 
    payload: sticker
})

export const updateStickersAction = (sticker) => ({
    type : UPDATE_STICKERS_ACTION, 
    payload : sticker
})

export const deleteStickersAction = (checkboxesChecked) => ({
    type : DELETE_STICKERS_ACTION, 
    payload : checkboxesChecked
})