
export const INITIALIZE_STICKERS_ACTION = "INITIALIZE_STICKERS_ACTION"
export const ADD_STICKERS_ACTION = "ADD_STICKERS_ACTION"
export const DELETE_STICKERS_ACTION = "DELETE_STICKERS_ACTION"
export const UPDATE_STICKERS_ACTION = "UPDATE_STICKERS_ACTION"


export default function stickersReducer(state = [], action) {
    switch (action.type) {
        case UPDATE_STICKERS_ACTION :
            const index = state.findIndex(sticker => sticker.idLiquid === action.payload.idLiquid)
            const newArray = state
            newArray[index].idBrand = action.payload.idBrand
            newArray[index].title1 = action.payload.title1
            newArray[index].title2 = action.payload.title2
            newArray[index].taste1 = action.payload.taste1
            newArray[index].taste2 = action.payload.taste2
            newArray[index].taste3 = action.payload.taste3
            newArray[index].pg1 = action.payload.pg1
            newArray[index].choicePg1 = action.payload.choicePg1
            newArray[index].price1 = action.payload.price1
            newArray[index].capacity1 = action.payload.capacity1
            newArray[index].pg2 = action.payload.pg2
            newArray[index].choicePg2 = action.payload.choicePg2
            newArray[index].price2 = action.payload.price2
            newArray[index].capacity2 = action.payload.capacity2
            
            return [...newArray]
            
        case DELETE_STICKERS_ACTION : 
            return state.filter(sticker => !action.payload.includes(sticker.idLiquid))
        case ADD_STICKERS_ACTION : 
            return [{...action.payload}, ...state]
        case INITIALIZE_STICKERS_ACTION : 
            return [...action.payload]
        default : 
            return state
    }
}