import { motion } from "framer-motion"

export default function DeletionConfirmation(props) {
    // checkboxes checked - loader - statusRequest - f° de suppression
    const handleClose = (e) => {
        e.preventDefault()
        props.setDeletionConfirmation(false)
    }  
    return (
        <motion.div 
            className = "deletionConfirmation"
            exit = {{transform : "scale(0)"}}
            animate = {{transform : "scale(1)"}}
            initial = {{transform : "scale(0)"}}
            transition= {{
                type : "linear"
            }}
            >
            <div>
                <p>Êtes-vous sûr de vouloir supprimer la liste sélectionnée ?</p>
                <div>
                    <button onClick = {handleClose}>NON</button>
                    <button onClick = {props.handleDelete}>OUI</button>
                </div>
            </div>
        </motion.div>
    )
}