import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import { initializeFlagsAction } from '../store/actions/flagsActions'
import axios from 'axios'
import {hostname} from '../config/utils'
import { initializeBrandsAction } from '../store/actions/brandsActions'
import { initializeStickersAction } from '../store/actions/stickersActions'
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Dashboard from './base/Dashboard'
import Header from './base/Header'
import AddFlag from './flags/AddFlag'
import CatalogFlag from './flags/CatalogFlag'
import AddBrand from './brands/AddBrand'
import CatalogBrand from './brands/CatalogBrand'
import AddSticker from './stickers/AddSticker'
import CatalogSticker from './stickers/CatalogSticker'


export default function Main({setLoader, setStatusRequest}) {
    const [header, setHeader] = useState(false)
    const [dataStatus, setDataStatus] = useState(false)

    const dispatch = useDispatch()
    
    if(localStorage.getItem('token') && !dataStatus) {
        setLoader(true)
        axios.get(`${hostname}/api/initialize`, {
            headers : {
                "authorization" : localStorage.getItem('token')
            }
        })
        .then(res => {
            dispatch(initializeFlagsAction(res.data.flags))
            dispatch(initializeBrandsAction(res.data.brands))
            dispatch(initializeStickersAction(res.data.stickers))
            setLoader(false)
        })
        .catch(err => {
            console.log(err)
            /*setLoader(false)
            if(err.response.status) {
                if(err.response.status === 401) {
                    setClientConnected(false)
                        localStorage.clear()
                } else {
                    console.log(err.response.data)
                    //setStatusRequest(err.response.data)
    
                }
            }*/
        })
        setDataStatus(true)
    }
    return ( 
        <Router>

            <Route 
                render = {({location}) => (
                    
                    <Switch location = {location} key = {location.pathname}>
                        <Route path = "/flag/add">
                            <Header 
                                header = {header}
                                setHeader = {setHeader}
                            />
                            <AddFlag 
                                header = {header}
                                setHeader = {setHeader}
                                setLoader = {setLoader}
                                setStatusRequest = {setStatusRequest}
                            />
                        </Route>
                        <Route path = "/flag/catalog">
                            <Header 
                                header = {header}
                                setHeader = {setHeader}
                            />
                            <CatalogFlag
                                setLoader = {setLoader}
                                setStatusRequest = {setStatusRequest}
                                header = {header}
                                setHeader = {setHeader}
                            />
                        </Route>
                        <Route path = "/brand/add">
                            <Header 
                                header = {header}
                                setHeader = {setHeader}
                            />
                            <AddBrand
                                setLoader = {setLoader}
                                setStatusRequest = {setStatusRequest}
                                header = {header}
                                setHeader = {setHeader}
                            />
                        </Route>
                        <Route path = "/brand/catalog">
                            <Header 
                                header = {header}
                                setHeader = {setHeader}
                            />
                            <CatalogBrand
                                setLoader = {setLoader}
                                setStatusRequest = {setStatusRequest}
                                header = {header}
                                setHeader = {setHeader}
                            />
                        </Route>
                        <Route path = "/sticker/add">
                            <Header 
                                header = {header}
                                setHeader = {setHeader}
                            />
                            <AddSticker
                                setLoader = {setLoader}
                                setStatusRequest = {setStatusRequest}
                                header = {header}
                                setHeader = {setHeader}
                            />
                        </Route>
                        <Route path = "/sticker/catalog">
                            <Header 
                                header = {header}
                                setHeader = {setHeader}
                            />
                            <CatalogSticker
                                setLoader = {setLoader}
                                setStatusRequest = {setStatusRequest}
                                header = {header}
                                setHeader = {setHeader}
                            />
                        </Route>
                        <Route path = "/">
                            <Header 
                                header = {header}
                                setHeader = {setHeader}
                            />
                            <Dashboard 
                            />
                        </Route>
                    </Switch>
                )} />

        </Router>
    )
}