import {motion} from 'framer-motion'
import LoaderCazaSticker from '../../svg/loader-caza.svg'
//                <img src = {loaderIMG} alt = "Loader" />
export default function Loader() {
    return ( 
        <div>
            <motion.div 
                exit = {{opacity : 0}}
                animate = {{opacity : 1}}
                initial = {{opacity : 0}}
                className = "loader" 
            >   
                <object type="image/svg+xml" data={LoaderCazaSticker}>Loader</object>

            </motion.div>
        </div> 
    )
}