import React, {useState} from 'react'
import { motion } from 'framer-motion'
import { styleInputValidation, conditionnalInputSticker, handleChangeStateSticker } from '../../config/formProcessingVariables'
import axios from 'axios'
import { hostname } from '../../config/utils'
import exemple from '../../images/image__icon.png'
import { useSelector, useDispatch } from 'react-redux'
import { brandsSelector } from '../../store/selectors/brandsSelector'
import noImage from '../../images/noImage.png'
import { flagsSelector } from '../../store/selectors/flagsSelector'
import { addStickersAction } from '../../store/actions/stickersActions'
import {Link} from 'react-router-dom'


export default function AddSticker(props) {
    //Sticker state
    var [sticker, setSticker] = useState({
        price1 : "", 
        capacity1 : "", 
        pg1 : "",
        choicePg1 : "", 
        price2 : "", 
        capacity2 : "", 
        pg2 : "",
        choicePg2 : ""
    })
    const [stickerValidation, setStickerValidation] = useState({})
    //Previews
    const [brandPreview, setBrandPreview] = useState("")
    const [flagPreview, setFlagPreview] = useState("")

    const allBrands = useSelector(brandsSelector)
    const allFlags = useSelector(flagsSelector)

    const dispatch = useDispatch()

    const [componentLoad, setComponentLoad] = useState(false)

    if(props.header && !componentLoad) {
        props.setHeader(false)
        setComponentLoad(true)
    }


    //Change input state
    const handleChangeInput = async (e) => {
        const value = e.target.value
        /*setSticker(state => {
            return {
                ...state,
                [e.target.name] : value
        }})*/

        handleChangeStateSticker(e, setSticker)
        
 

        setStickerValidation(state => {
            return {
                ...state,
                [e.target.name] : conditionnalInputSticker(e)
        }})


        if(e.target.name === "idBrand") {
            const valueParse = parseInt(value)
            if(value === "default") {
                setBrandPreview('')
                setFlagPreview('')
            } else if (allBrands.find(brand => brand.idBrand === valueParse)) {
                const brandSelected = allBrands.find(brand => parseInt(brand.idBrand) === valueParse)
                setBrandPreview(`${hostname}/api/images/brands/${brandSelected.linkBrand}`)
                if(allFlags.filter(flag => flag.idFlag === parseInt(brandSelected.idFlag)).length > 0 ) {
                    const flagSelected = allFlags.find(flag => parseInt(flag.idFlag) === parseInt(brandSelected.idFlag))
                    setFlagPreview(`${hostname}/api/images/flags/${flagSelected.linkFlag}`)
                } else {
                    setFlagPreview(noImage)
                }
                
            } else {
                setBrandPreview(noImage)
                setFlagPreview(noImage)
            }
        }
    }

    //Prices
    const [secondPrice, setSecondPrice] = useState(false)

    //Submit
    const handleSubmit = (e) => {
        e.preventDefault()
        props.setLoader(true)

        // Si les validations minimales sont à true
        if(stickerValidation.idBrand && stickerValidation.title1 && stickerValidation.taste1 && stickerValidation.price1 && stickerValidation.capacity1 && stickerValidation.choicePg1 && stickerValidation.pg1) {
            //S'il y a un second prix
            if(secondPrice) {
                //Si toutes les informations du second prix sont remplies
                if (stickerValidation.price2 && stickerValidation.capacity2 && stickerValidation.choicePg2 && stickerValidation.pg2) {
                    const formContainer = {
                        idBrand : sticker.idBrand, 
                        title1 : sticker.title1, 
                        title2 : sticker.title2, 
                        taste1 : sticker.taste1, 
                        taste2 : sticker.taste2, 
                        taste3 : sticker.taste3, 
                        price1 : sticker.price1, 
                        capacity1 : sticker.capacity1, 
                        choicePg1 : sticker.choicePg1, 
                        pg1 : sticker.pg1,
                        price2 : sticker.price2, 
                        capacity2 : sticker.capacity2, 
                        choicePg2 : sticker.choicePg2, 
                        pg2 : sticker.pg2
                    }
    
                    axios.post(`${hostname}/api/stickers/add`, formContainer, {
                        headers : {
                            "authorization" : localStorage.getItem('token')
                        }
                    })
                    .then(res => { 
                        dispatch(addStickersAction(res.data.addedSticker))
                        props.setLoader(false)
                        props.setStatusRequest(res.data)
                    })
                    .catch(err => {
                        props.setLoader(false)
                        if(err.response === undefined) {
                            props.setStatusRequest({
                                status : false, 
                                message : "Vous avez été déconnécté du serveur, veuillez vous reconnecter."
                            })
                        } else {
                            props.setStatusRequest(err.response.data)
                        }
                    })
                    
                } else {
                    props.setLoader(false)
                    const validationRequest = [ 
                        {nameInput : 'price2', message : "Veuillez entrer le prix du liquide"}, 
                        {nameInput : 'capacity2', message : "Veuillez entrer la contenance du liquide"}, 
                        {nameInput : 'choicePg2', message : "Veuillez choisir le type de pg"}, 
                        {nameInput : 'pg2', message : "Veuillez entrer le taux du liquide"}, 
                    ]

                    validationRequest.map(validation => {
                        if(!stickerValidation[validation.nameInput] || stickerValidation[validation.nameInput] === null) {
                            setStickerValidation(state => {
                                return {
                                    ...state, 
                                    [validation.nameInput] : false
                                }
                            })
                        }
        
                        const falseValidation = Object.values(stickerValidation).filter(validation => validation === false).length
        
                        if(falseValidation === 1) {
                            if (!stickerValidation[validation.nameInput]) {
                                props.setStatusRequest({
                                    status : false, 
                                    message : validation.message
                                })

                                
                            }
                        } else {
                            props.setStatusRequest({
                                status : false, 
                                message : "Votre demande est incomplète"
                            })

                        }
                    })


                    
                
                }


            } else {
                const formContainer = {
                    idBrand : sticker.idBrand, 
                    title1 : sticker.title1, 
                    title2 : sticker.title2, 
                    taste1 : sticker.taste1, 
                    taste2 : sticker.taste2, 
                    taste3 : sticker.taste3, 
                    price1 : sticker.price1, 
                    capacity1 : sticker.capacity1, 
                    choicePg1 : sticker.choicePg1, 
                    pg1 : sticker.pg1
                }

                axios.post(`${hostname}/api/stickers/add`, formContainer, {
                    headers : {
                        "authorization" : localStorage.getItem('token')
                    }
                })
                .then(res => { 
                    dispatch(addStickersAction(res.data.addedSticker))
                    props.setLoader(false)
                    props.setStatusRequest(res.data)
                })
                .catch(err => {
                    props.setLoader(false)
                    if(err.response === undefined) {
                        props.setStatusRequest({
                            status : false, 
                            message : "Vous avez été déconnécté du serveur, veuillez vous reconnecter."
                        })
                    } else {
                        props.setStatusRequest(err.response.data)
                    }
                })

            }
        } else {
            props.setLoader(false)
            const validationRequest = [
                {nameInput : 'idBrand', message : "Veuillez choisir une marque"}, 
                {nameInput : 'title1', message : "Veuillez entrer le titre de l'étiquette"}, 
                {nameInput : 'taste1', message : "Veuillez entrer le goût du liquide"}, 
                {nameInput : 'price1', message : "Veuillez entrer le prix du liquide"}, 
                {nameInput : 'capacity1', message : "Veuillez entrer la contenance du liquide"}, 
                {nameInput : 'choicePg1', message : "Veuillez choisir le type de pg"}, 
                {nameInput : 'pg1', message : "Veuillez entrer le taux du liquide"}, 
            ]

            validationRequest.map(validation => {
                if(!stickerValidation[validation.nameInput] || stickerValidation[validation.nameInput] === null) {
                    setStickerValidation(state => {
                        return {
                            ...state, 
                            [validation.nameInput] : false
                        }
                    })
                }

                const falseValidation = Object.values(stickerValidation).filter(validation => validation === false).length

                if(falseValidation === 1) {
                    if (!stickerValidation[validation.nameInput]) {
                        props.setStatusRequest({
                            status : false, 
                            message : validation.message
                        })
                    }
                } else {
                    props.setStatusRequest({
                        status : false, 
                        message : "Votre demande est incomplète"
                    })
                }
            })

        }
    }
    
    return ( 
        <motion.form 
        exit = {{transform : "scale(0)"}}
        animate = {{transform : "scale(1)"}}
        initial = {{transform : "scale(0)"}}
        className = "addSticker" 
        onSubmit = {handleSubmit}
    >
        <div className = "addSticker__create">
            <div className = "catalog__header--title">Ajouter une étiquette</div>
            <Brand
                sticker = {sticker}
                stickerValidation = {stickerValidation}
                handleChangeInput = {handleChangeInput}
            />

            <Titles 
                sticker = {sticker}
                stickerValidation = {stickerValidation}
                handleChangeInput = {handleChangeInput}
            />

            <Tastes 
                sticker = {sticker}
                stickerValidation = {stickerValidation}
                handleChangeInput = {handleChangeInput}
            />

            <Prices 
                sticker = {sticker}
                stickerValidation = {stickerValidation}
                handleChangeInput = {handleChangeInput}
                secondPrice = {secondPrice}
                setSecondPrice = {setSecondPrice}
            />
        </div>
        <div className = "addSticker__preview">
            <Preview
                sticker = {sticker}
                setSticker = {setSticker}
                setStickerValidation = {setStickerValidation}
                secondPrice = {secondPrice}
                brandPreview = {brandPreview}
                flagPreview = {flagPreview}
            />
        </div>
    </motion.form>
    )
}

export function Brand(props) {
    const allBrands = useSelector(brandsSelector)
    return (
        <div className = "addSticker__create__content">
        <label htmlFor = "brand">À quelle marque correspond votre liquide ?</label>
        <select value = {props.sticker.idBrand} name = "idBrand" onChange  = {props.handleChangeInput} style = {{border : styleInputValidation(props.stickerValidation, 'idBrand')}}>
            <option value = "default">Choisissez la marque</option>
            {
                allBrands.length > 0 ? 
                    allBrands.map((brand, index) => (
                        <option key = {index} value = {brand.idBrand}>{brand.nameBrand}</option>
                    ))
                    :
            null
        
            }
        </select>
        </div>
    )
}

export function Titles(props) {
    return (
        <div className = "addSticker__create__content">
            <label htmlFor = "titles">Quel est le nom du liquide</label>
            <input type = "text" name = "title1" onChange = {props.handleChangeInput}value = {props.sticker.title1} style = {{border : styleInputValidation(props.stickerValidation, 'title1')}} maxLength="15"/>
            <input type = "text" value = {props.sticker.title2} name = "title2" onChange = {props.handleChangeInput} style = {{border : styleInputValidation(props.stickerValidation, 'title2')}} maxLength="15"/>
    </div>
    )
}

export function Tastes(props) {
    return (
        <div className = "addSticker__create__content">
            <label htmlFor = "tastes">Quel est le goût du liquide ?</label>
            <input type = "text" name = "taste1" value = {props.sticker.taste1} onChange = {props.handleChangeInput} style = {{border : styleInputValidation(props.stickerValidation, 'taste1')}} maxLength="28"/>
            <input type = "text" name = "taste2" value = {props.sticker.taste2} onChange = {props.handleChangeInput} style = {{border : styleInputValidation(props.stickerValidation, 'taste2')}} maxLength="28"/>
            <input type = "text" name = "taste3" value = {props.sticker.taste3} onChange = {props.handleChangeInput} style = {{border : styleInputValidation(props.stickerValidation, 'taste3')}} maxLength="28"/>
        </div>
    )
}

export function Prices(props) {
    const handleClickSecondPrice = (e) => {
        e.preventDefault()
        props.setSecondPrice(!props.secondPrice)
    }
    return (
        <div className = "addSticker__create__content addSticker__create__content--flexDirection">
        <div className = "addSticker__create__content__price">
            <div className = "addSticker__create__content__price--element">
                <label htmlFor = 'price1'>Prix </label>
                <input type = "text" name = "price1"  pattern = {"(^$)|([0-9]{0,4}).?([0-9]{0,2})?[^,a-zA-Z]$"} step={0.01} value = {props.sticker.price1} onChange= {props.handleChangeInput} style = {{border : styleInputValidation(props.stickerValidation, 'price1')}}/>
            </div>
            <div className = "addSticker__create__content__price--element">
                <label htmlFor = 'capacity1'>Contenance (ml) </label>
                <input type = "text" name = "capacity1" pattern = {"(^$)|([0-9]{0,4}).?([0-9]{0,2})?[^,a-zA-Z]$"} value = {props.sticker.capacity1} onChange = {props.handleChangeInput} style = {{border : styleInputValidation(props.stickerValidation, 'capacity1')}} />
            </div>
            <div className = "addSticker__create__content__price--element">
                <label htmlFor = 'choicePg1'>Type </label>
                <select value = {props.sticker.choicePg1} name = "choicePg1" onChange = {props.handleChangeInput} style = {{border : styleInputValidation(props.stickerValidation, 'choicePg1')}}>
                    <option value = "default">Choisissez le type</option>
                    <option value = "PG">PG</option>
                    <option value = "MPGV">MPGV</option>
                    <option value = "VE">VE</option>
                </select>
            </div>
            <div className = "addSticker__create__content__price--element">
                <label htmlFor = 'pg'>Pourcentage </label>
                <input type = "text" value = {props.sticker.pg1} pattern = {"(^$)|([0-9]{0,4}).?([0-9]{0,2})?[^,a-zA-Z]$"} name = "pg1" onChange = {props.handleChangeInput} style = {{border : styleInputValidation(props.stickerValidation, 'pg1')}}/>
            </div>
        </div>
        
        {props.secondPrice ? 
            <motion.div 
                exit = {{transform : "scale(0)"}}
                animate = {{transform : "scale(1)"}}
                initial = {{transform : "scale(0)"}}
                className = "addSticker__create__content__price"
            >
                <button className = "addSticker__create__content__price--buttonClose" onClick = {handleClickSecondPrice}>-</button>
                <div className = "addSticker__create__content__price--element">
                    <label htmlFor = 'price2'>Prix  </label>
                    <input type = "text" name = "price2" pattern = {"(^$)|([0-9]{0,4}).?([0-9]{0,2})?[^,a-zA-Z]$"} value = {props.sticker.price2} onChange= {props.handleChangeInput} style = {{border : styleInputValidation(props.stickerValidation, 'price2')}} step={0.01} />
                </div>
                <div className = "addSticker__create__content__price--element">
                    <label htmlFor = 'capacity2'>Contenance (ml) </label>
                    <input type = "text" name = "capacity2" pattern = {"(^$)|^[0-9]{0,3}[^,a-zA-Z]$"} value = {props.sticker.capacity2} onChange = {props.handleChangeInput} style = {{border : styleInputValidation(props.stickerValidation, 'capacity2')}} />
                </div>
                <div className = "addSticker__create__content__price--element">
                    <label htmlFor = 'choicePg2'>Type  </label>
                    <select name = "choicePg2" value = {props.sticker.choicePg2} onChange = {props.handleChangeInput} style = {{border : styleInputValidation(props.stickerValidation, 'choicePg2')}}>
                        <option value = "default">Choisissez le type</option>
                        <option value = "PG">PG</option>
                        <option value = "MPGV">MPGV</option>
                        <option value = "VE">VE</option>
                    </select>
                </div>
                <div className = "addSticker__create__content__price--element">
                    <label htmlFor = 'pg2'>Pourcentage </label>
                    <input type = "text" name = "pg2" pattern = {"(^$)|^[0-9]{0,3}[^,a-zA-Z]$"} value = {props.sticker.pg2} onChange = {props.handleChangeInput} style = {{border : styleInputValidation(props.stickerValidation, 'pg2')}}/>
                </div>
            </motion.div>
    
        :
        <div className = "addSticker__create__content__price--buttonOpen">
            <button  onClick = {handleClickSecondPrice}>+</button>
            <div>Ajouter une contenance</div>
        </div>
        }


    </div>
    )
}


export function Preview(props) {
    return (
        <React.Fragment>
            <div className = "addSticker__preview__content">
                <div className = "addSticker__preview__content__title">Prévisualisation</div>
                <div className = "addSticker__preview__content__generate">
                    <div className = "row1">
                        <div className = "row1--brand">
                            <img src = {!props.brandPreview ? exemple : props.brandPreview} alt = "Exemple" />
                        </div>
                        <div className = "row1--title">
                            <span>{props.sticker.title1}</span>
                            <span>{props.sticker.title2}</span>
                        </div>
                    </div>

                    <div className = "row2">
                        <span>{props.sticker.taste1}</span>
                        <span>{props.sticker.taste2}</span>
                        <span>{props.sticker.taste3}</span>
                    </div>

                    <div className = "row3">
                        <div className = "row3--flag">
                            <img src = {!props.flagPreview ? exemple : props.flagPreview} alt = "Exemple" />
                        </div>
                        <div className = "row3__prices">
                            <div className = "row3__prices--price1">{props.sticker.price1}€-{props.sticker.capacity1}ml-{props.sticker.pg1}{props.sticker.choicePg1}/{'100' - props.sticker.pg1}VG</div>
                            
                            {props.secondPrice === true ? 
                                <div className = "row3__prices--price2">{props.sticker.price2}€-{props.sticker.capacity2}ml-{props.sticker.pg2}{props.sticker.choicePg2}/{'100' - props.sticker.pg2}VG</div>
                            : null
                            }
                        </div>
                    </div>
                </div>
                <div className = "addSticker__preview__content__buttons">
                    <Link to = {`/CazaSticker2/sticker/catalog`}>Catalogue</Link>
                    <button>Valider</button>
                </div>
            </div>
        </React.Fragment>
    )
}
