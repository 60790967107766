import React, {useState, useRef, useEffect} from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import axios from 'axios'
import { handleChangeStateSticker } from '../../config/formProcessingVariables'
import { Brand, Preview, Prices, Tastes, Titles } from '../stickers/AddSticker'
import { hostname } from '../../config/utils'
import {  conditionnalInputSticker } from '../../config/formProcessingVariables'
import editIcon from '../../images/edit-icon.png'
import {ReactComponent as CloseSVG} from '../../svg/icon-close.svg'
import noImage from '../../images/noImage.png'
import { useSelector, useDispatch } from 'react-redux'
import { stickersSelector } from '../../store/selectors/stickersSelector'
import { brandsSelector } from '../../store/selectors/brandsSelector'
import { flagsSelector } from '../../store/selectors/flagsSelector'
import { deleteStickersAction, updateStickersAction } from '../../store/actions/stickersActions'
import DeletionConfirmation from '../base/DeletionConfirmation'
import {Link} from 'react-router-dom'



export default function CatalogSticker(props) {
        //Catalog content state
        const [dataStickers, setDataStickers] = useState([])
        const [checkboxesChecked, setCheckboxesChecked] = useState([])
        const [dataStatus, setDataStatus] = useState(false)
        const [deletionConfirmation, setDeletionConfirmation] = useState(false)
        const [detectSelect, setDetectSelect] = useState(false)
        const dispatch = useDispatch()

        //REDUX
        const allStickers = useSelector(stickersSelector)
        const allBrands = useSelector(brandsSelector)
        const allFlags = useSelector(flagsSelector)
    
        if(allStickers.length > 0 && !dataStatus) {
            setDataStickers(allStickers)
            setDataStatus(true)
            if(props.header) {
                props.setHeader(false)
            }
        }


        //Update window state
        const [updateBox, setUpdateBox] = useState(false)
        const [sticker, setSticker] = useState({})
        const [stickerValidation, setStickerValidation] = useState({})
        const [secondPrice, setSecondPrice] = useState(false)
        const [brandPreview, setBrandPreview] = useState("")
        const [flagPreview, setFlagPreview] = useState("")
        const [printBox, setPrintBox] = useState(false)

        const handleChangeInput = async (e) => {
            const value = e.target.value
            handleChangeStateSticker(e, setSticker)
    
            setStickerValidation(state => {
                return {
                    ...state,
                    [e.target.name] : conditionnalInputSticker(e)
            }})
    
            if(e.target.name === "idBrand") {
                const valueParse = parseInt(value)
                if(value === "default") {
                    setBrandPreview('')
                    setFlagPreview('')
                } else if (allBrands.find(brand => brand.idBrand === valueParse)) {
                    const brandSelected = allBrands.find(brand => brand.idBrand === valueParse)
                    setBrandPreview(`${hostname}/api/images/brands/${brandSelected.linkBrand}`)
                    if(allFlags.find(flag => flag.idFlag === brandSelected.idFlag)) {
                        const flagSelected = allFlags.find(flag => flag.idFlag === brandSelected.idFlag)
                        setFlagPreview(`${hostname}/api/images/flags/${flagSelected.linkFlag}`)
                    } else {
                        setFlagPreview(noImage)
                    }
                    
                } else {
                    setBrandPreview(noImage)
                    setFlagPreview(noImage)
                }
            }
        }

        const handleDelete = (e) => {
            e.preventDefault()
            props.setLoader(true)
            setDeletionConfirmation(false)
            if(checkboxesChecked.length > 0) {
                axios.post(`${hostname}/api/stickers/delete`, checkboxesChecked, {
                    headers : {
                        "authorization" : localStorage.getItem('token')
                    }
                })
                    .then(res => {
    
                        dispatch(deleteStickersAction(checkboxesChecked))
                        props.setStatusRequest(res.data)
                        props.setLoader(false)
                        setCheckboxesChecked([])
                    })
                    .catch(err => {
                        //console.log(err)
                        props.setLoader(false)
                        if(err.response === undefined) {
                            props.setStatusRequest({
                                status : false, 
                                message : "Vous avez été déconnécté du serveur, veuillez vous reconnecter."
                            })
                        } else {
                            props.setStatusRequest(err.response.data)
                        }
    
    
                    })
            } else {
                props.setLoader(false)
                props.setStatusRequest({
                    status : false, 
                    message : "Veuillez sélectionner une étiquette"
                })
            }
        }


    return (
        <React.Fragment>
            <div className = "catalog__header--title">Catalogue des étiquettes</div>
            <AnimatePresence exitBeforeEnter initial = {false}>
            {printBox ? 
                <PrintBox
                    setPrintBox = {setPrintBox}
                    printBox = {printBox}
                    checkboxesChecked = {checkboxesChecked}
                    dataStickers = {dataStickers}

                />
        
            : 
            
            null
            
            }
            </AnimatePresence>
            <AnimatePresence exitBeforeEnter initial = {false}>
            {updateBox ? 
                <UpdateWindow 
                    sticker = {sticker}
                    stickerValidation = {stickerValidation}
                    setStickerValidation = {setStickerValidation}
                    handleChangeInput = {handleChangeInput}
                    secondPrice = {secondPrice}
                    setSecondPrice = {setSecondPrice}
                    brandPreview = {brandPreview}
                    flagPreview = {flagPreview}
                    updateBox = {updateBox}
                    setUpdateBox = {setUpdateBox}
                    setLoader = {props.setLoader}
                    setStatusRequest = {props.setStatusRequest}
                    updateStickerEndpoint = {props.updateStickerEndpoint}
                    socket = {props.socket}
                />
            : null
            }
            </AnimatePresence>
            <AnimatePresence exitBeforeEnter initial = {false}>
            {
                deletionConfirmation ? 
                    <DeletionConfirmation
                        setDeletionConfirmation = {setDeletionConfirmation}
                        handleDelete = {handleDelete}
                    />
                :
                null
            }
            </AnimatePresence>
            <motion.div 
                exit = {{x : 1000, }}
                animate = {{x : 0 }}
                initial = {{x : 1000}}
                transition = {{
                    type : "linear", 
                }}
                className = "catalog"
            >
                <HeaderCatalog
                    allFlags = {props.allFlags}
                    setDataStickers = {setDataStickers}
                    checkboxesChecked = {checkboxesChecked}
                />

                <CatalogContent 
                    dataStickers = {dataStickers}
                    checkboxesChecked = {checkboxesChecked}
                    setCheckboxesChecked = {setCheckboxesChecked}
                    setDataStickers = {setDataStickers}
                    detectSelect = {detectSelect}
                    setDetectSelect = {setDetectSelect}
                />
            </motion.div>
            <Cart 
                checkboxesChecked = {checkboxesChecked}
                setCheckboxesChecked = {setCheckboxesChecked}
                dataStickers = {dataStickers}
                setDataStickers = {setDataStickers}
                updateBox = {updateBox}
                setUpdateBox = {setUpdateBox}
                setSticker = {setSticker}
                setFlagPreview = {setFlagPreview}
                setBrandPreview = {setBrandPreview}
                setLoader = {props.setLoader}
                setStatusRequest = {props.setStatusRequest}
                brandPreview = {brandPreview}
                setSecondPrice = {setSecondPrice}
                setStickerValidation = {setStickerValidation}
                printBox = {printBox}
                setPrintBox = {setPrintBox}
                deleteStickerEndpoint = {props.deleteStickerEndpoint}
                sticker = {sticker}
                socket = {props.socket}
                setDeletionConfirmation = {setDeletionConfirmation}
                detectSelect = {detectSelect}
                setHeader = {props.setHeader}
                header = {props.header}
                />
        </React.Fragment>
    )
}

function UpdateWindow(props) {
    const dispatch = useDispatch()
    const handleUpdate = (e) => {
        e.preventDefault()
        props.setLoader(true)
        // Si les validations minimales sont à true
        if(props.stickerValidation.idBrand && props.stickerValidation.title1 && props.stickerValidation.taste1 && props.stickerValidation.price1 && props.stickerValidation.capacity1 && props.stickerValidation.choicePg1 && props.stickerValidation.pg1) {
            //S'il y a un second prix
            if(props.secondPrice) {
                //Si toutes les informations du second prix sont remplies
                if (props.stickerValidation.price2 && props.stickerValidation.capacity2 && props.stickerValidation.choicePg2 && props.stickerValidation.pg2) {
                    const formContainer = {
                        idLiquid : props.sticker.idLiquid, 
                        idBrand : props.sticker.idBrand, 
                        title1 : props.sticker.title1, 
                        title2 : props.sticker.title2, 
                        taste1 : props.sticker.taste1, 
                        taste2 : props.sticker.taste2, 
                        taste3 : props.sticker.taste3, 
                        price1 : props.sticker.price1, 
                        capacity1 : props.sticker.capacity1, 
                        choicePg1 : props.sticker.choicePg1, 
                        pg1 : props.sticker.pg1,
                        price2 : props.sticker.price2, 
                        capacity2 : props.sticker.capacity2, 
                        choicePg2 : props.sticker.choicePg2, 
                        pg2 : props.sticker.pg2
                    }
    
                    axios.post(`${hostname}/api/stickers/update`, formContainer, {
                        headers : {
                            "authorization" : localStorage.getItem('token')
                        }
                    })
                    .then(res => { 
                        dispatch(updateStickersAction(res.data.updatedSticker))
                        props.setLoader(false)
                        props.setUpdateBox(false)
                        props.setStatusRequest(res.data)
                    })
                    .catch(err => {
                        props.setLoader(false)
                        if(err.response === undefined) {
                            props.setStatusRequest({
                                status : false, 
                                message : "Vous avez été déconnécté du serveur, veuillez vous reconnecter."
                            })
                        } else {
                            props.setStatusRequest(err.response.data)
                        }   
                    })
                    
                } else {
                    props.setLoader(false)
                    const validationRequest = [ 
                        {nameInput : 'price2', message : "Veuillez entrer le prix du liquide"}, 
                        {nameInput : 'capacity2', message : "Veuillez entrer la contenance du liquide"}, 
                        {nameInput : 'choicePg2', message : "Veuillez choisir le type de pg"}, 
                        {nameInput : 'pg2', message : "Veuillez entrer le taux du liquide"}, 
                    ]

                    validationRequest.map(validation => {
                        if(!props.stickerValidation[validation.nameInput] || props.stickerValidation[validation.nameInput] === null) {
                            props.setStickerValidation(state => {
                                return {
                                    ...state, 
                                    [validation.nameInput] : false
                                }
                            })
                        }
        
                        const falseValidation = Object.values(props.stickerValidation).filter(validation => validation === false).length
        
                        if(falseValidation === 1) {
                            if (!props.stickerValidation[validation.nameInput]) {
                                props.setStatusRequest({
                                    status : false, 
                                    message : validation.message
                                })

                                
                            }
                        } else {
                            props.setStatusRequest({
                                status : false, 
                                message : "Votre demande est incomplète"
                            })

                        }
                    })
                }


            } else {
                const formContainer = {
                    idLiquid : props.sticker.idLiquid, 
                    idBrand : props.sticker.idBrand, 
                    title1 : props.sticker.title1, 
                    title2 : props.sticker.title2, 
                    taste1 : props.sticker.taste1, 
                    taste2 : props.sticker.taste2, 
                    taste3 : props.sticker.taste3, 
                    price1 : props.sticker.price1, 
                    capacity1 : props.sticker.capacity1, 
                    choicePg1 : props.sticker.choicePg1, 
                    pg1 : props.sticker.pg1
                }
                axios.post(`${hostname}/api/stickers/update`, formContainer, {
                    headers : {
                        "authorization" : localStorage.getItem('token')
                    }
                })
                .then(res => { 
                    dispatch(updateStickersAction(res.data.updatedSticker))
                    props.setUpdateBox(false)
                    props.setLoader(false)
                    props.setStatusRequest(res.data)
                })
                .catch(err => {
                    props.setLoader(false)
                    if(err.response === undefined) {
                        props.setStatusRequest({
                            status : false, 
                            message : "Vous avez été déconnécté du serveur, veuillez vous reconnecter."
                        })
                    } else {
                        props.setStatusRequest(err.response.data)
                    }
                })

            }
        } else {
            props.setLoader(false)
            const validationRequest = [
                {nameInput : 'idBrand', message : "Veuillez choisir une marque"}, 
                {nameInput : 'title1', message : "Veuillez entrer le titre de l'étiquette"}, 
                {nameInput : 'taste1', message : "Veuillez entrer le goût du liquide"}, 
                {nameInput : 'price1', message : "Veuillez entrer le prix du liquide"}, 
                {nameInput : 'capacity1', message : "Veuillez entrer la contenance du liquide"}, 
                {nameInput : 'choicePg1', message : "Veuillez choisir le type de pg"}, 
                {nameInput : 'pg1', message : "Veuillez entrer le taux du liquide"}, 
            ]

            validationRequest.map(validation => {
                if(!props.stickerValidation[validation.nameInput] || props.stickerValidation[validation.nameInput] === null) {
                    props.setStickerValidation(state => {
                        return {
                            ...state, 
                            [validation.nameInput] : false
                        }
                    })
                }

                const falseValidation = Object.values(props.stickerValidation).filter(validation => validation === false).length

                if(falseValidation === 1) {
                    if (!props.stickerValidation[validation.nameInput]) {
                        props.setStatusRequest({
                            status : false, 
                            message : validation.message
                        })
                    }
                } else {
                    props.setStatusRequest({
                        status : false, 
                        message : "Votre demande est incomplète"
                    })
                }
            })

        }
    }

    const handleClose = (e) => {
        e.preventDefault()
        props.setUpdateBox(!props.updateBox)
        props.setStickerValidation({})
    }
    return (
        <motion.div 
            exit = {{transform : "scale(0)"}}
            animate = {{transform : "scale(1)"}}
            initial = {{transform : "scale(0)"}}
            className = "catalog__update"
        >   
            <div className = "catalog__update__close"><CloseSVG onClick = {handleClose}/></div>
            <form onSubmit = {handleUpdate} className = "catalog__update__form" >
                <div className = "addSticker__create">
                    <Brand 
                        sticker = {props.sticker}
                        stickerValidation = {props.stickerValidation}
                        handleChangeInput = {props.handleChangeInput}
                    />

                    <Titles 
                        sticker = {props.sticker}
                        stickerValidation = {props.stickerValidation}
                        handleChangeInput = {props.handleChangeInput}
                    />

                    <Tastes 
                        sticker = {props.sticker}
                        stickerValidation = {props.stickerValidation}
                        handleChangeInput = {props.handleChangeInput}
                    />

                    <Prices 
                        sticker = {props.sticker}
                        stickerValidation = {props.stickerValidation}
                        handleChangeInput = {props.handleChangeInput}
                        secondPrice = {props.secondPrice}
                        setSecondPrice = {props.setSecondPrice}
                    
                    />
                </div>
                <div className = "addSticker__preview">
                    <Preview
                        sticker = {props.sticker}
                        secondPrice = {props.secondPrice}
                        brandPreview = {props.brandPreview}
                        flagPreview = {props.flagPreview}
                    />
                </div>
            </form>
        </motion.div>
    )
}

function PrintBox(props) {
    const allBrands = useSelector(brandsSelector)
    const allFlags = useSelector(flagsSelector)
    const allStickers = useSelector(stickersSelector)

    const dataPrint = allStickers.filter((sticker, index) => props.checkboxesChecked.includes(sticker.idLiquid))

    const handleClose = (e) => {
        e.preventDefault()
        props.setPrintBox(!props.printBox)
    }

    const handlePrint = (e) => {
        e.preventDefault()
        window.print()
    }

    return (
        <motion.div 
            exit = {{transform : "scale(0)"}}
            animate = {{transform : "scale(1)"}}
            initial = {{transform : "scale(0)"}}
            transition = {{
                type : 'linear'
            }}
            className = "print"
        >
            <div className = "print--buttons">
                <button onClick = {handlePrint}>Imprimer</button>
                <button onClick = {handleClose}>Fermer</button>
            </div>

            <div className = "print__content">
                {dataPrint.map(sticker => (
                    <div className = "print__content__sticker">
                        <div className = "print__content__sticker__row1">
                            <div className = "print__content__sticker__row1--brand">
                            {
                                allBrands.find(brand => brand.idBrand === parseInt(sticker.idBrand)) ? 
                                    <img src = {`${hostname}/api/images/brands/${allBrands.find(brand => brand.idBrand === parseInt(sticker.idBrand)).linkBrand}`} alt = {allBrands.find(brand => brand.idBrand === parseInt(sticker.idBrand)).nameBrand} />
                                : 
                                    <img src = {noImage} alt = "Pas de marque liée"/>
                            }
                            </div>
                            <div className = "print__content__sticker__row1__titles">
                                <span className = "print__content__sticker__row1__titles--title1">{sticker.title1}</span>
                                <br/>
                                <span className = "print__content__sticker__row1__titles--title2">{sticker.title2}</span>
                            </div>

                        
                        </div>
                        <div className = "print__content__sticker__row2">
                            <div className = "print__content__sticker__row2--taste1">{sticker.taste1}</div>
                            <div className = "print__content__sticker__row2--taste2">{sticker.taste2}</div>
                            <div className = "print__content__sticker__row2--taste3">{sticker.taste3}</div>
                        </div>

                        <div className = "print__content__sticker__row3">
                            <div className = "print__content__sticker__row3--flag">
                                {
                                    allBrands.find(brand => brand.idBrand === parseInt(sticker.idBrand)) && allFlags.find(flag => flag.idFlag === allBrands.find(brand => brand.idBrand === parseInt(sticker.idBrand)).idFlag) ? 
                                        <img src = {`${hostname}/api/images/flags/${allFlags.find(flag => flag.idFlag === allBrands.find(brand => brand.idBrand === parseInt(sticker.idBrand)).idFlag).linkFlag}`} alt = {allFlags.find(flag => flag.idFlag === allBrands.find(brand => brand.idBrand === parseInt(sticker.idBrand)).idFlag).nameFlag} />
                                    : 
                                        <img src = {noImage} alt = "Pas de drapeau lié"/>
                                }
                            </div>
                            <div className = "print__content__sticker__row3__prices">
                                <div className = "print__content__sticker__row3__prices--price1">{`${sticker.price1}€-${sticker.capacity1}ml-${sticker.pg1}${sticker.choicePg1}/${100 - sticker.pg1}`}VG</div>
                                {sticker.pg2 ? 
                                    <div className = "print__content__sticker__row3__prices--price2">{`${sticker.price2}€-${sticker.capacity2}ml-${sticker.pg2}${sticker.choicePg2}/${100 - sticker.pg2}`}VG</div>
                                    : 
                                    null
                                }
                            </div>
                        </div>
                    </div>
            ))}

            </div>
            
        </motion.div>
    )
}

function HeaderCatalog(props) {
    const [searchLiquid, setSearchLiquid]  = useState("")
    const [filterBrand, setFilterBrand] = useState(null)

    const allStickers = useSelector(stickersSelector)
    const allBrands = useSelector(brandsSelector)
    const tagSelectData = allBrands

    const handleSearchLiquid = async (e) => {
        const value = e.target.value
        setSearchLiquid(value)
        const searchString = value.trim().toLowerCase()
        const searchData = await allStickers.filter(sticker => `${sticker.title1} ${sticker.title2}`.toLowerCase().match(searchString))

        const dataFilter = await searchData.filter(sticker => !props.checkboxesChecked.includes(sticker.idLiquid))

        props.setDataStickers(dataFilter)
    }

    const handleChangeFilterBrand = async (e) => {
        if (e.target.value !== "default") {
            const value = parseInt(e.target.value)
            setFilterBrand(value)
            const searchData = await allStickers.filter(sticker => sticker.idBrand === value)

            const dataFilter = await searchData.filter(sticker => !props.checkboxesChecked.includes(sticker.idLiquid))

            props.setDataStickers(dataFilter)
        } else {
            setFilterBrand("default")
            const dataFilter = await allStickers.filter(sticker => !props.checkboxesChecked.includes(sticker.idLiquid))
            props.setDataStickers(dataFilter)
        }
    }

    return (
        <div className = "catalog__header">

            <div className = "catalog__header--filter">
            <input type = "text" onChange = {handleSearchLiquid} value = {searchLiquid} placeholder = "Rechercher un liquide"/>
            <select onChange = {handleChangeFilterBrand} value = {filterBrand}>
                <option value = "default">Trier par marque</option>
                
                {
                allBrands.length > 0 ? 
                    tagSelectData.map(brand => (
                        <option key = {brand.idBrand} value = {brand.idBrand}>{brand.nameBrand}</option>
                    ))
                :
                    null
                }
            </select>
            <Link to = {`/CazaSticker2/sticker/add`}>Ajouter</Link>
        </div>
        </div>
    )
}

function Cart(props) {
    const cart = useRef(null)
    const allStickers = useSelector(stickersSelector)
    const allBrands = useSelector(brandsSelector)
    const allFlags = useSelector(flagsSelector)

    const handleClickCart = (e) => {
        e.preventDefault()
        const cartCurrent = cart.current

        cartCurrent.classList.toggle('is-cart-open')
        if(props.header) {
            props.setHeader(false)
        }
    }

    const handleDeselect = async (idLiquid) => {
        const newCheckboxes = props.checkboxesChecked.filter(check => check !== idLiquid)
        props.setCheckboxesChecked(newCheckboxes)     
        props.setDataStickers(allStickers.filter(sticker => !newCheckboxes.includes(sticker.idLiquid)))
    }
    

    const handleUpdate = async (e, checked) => {
        e.preventDefault()
        await allStickers.map((sticker)=> {
            if(sticker.idLiquid === checked) {
                props.setSticker(sticker)

                if (allBrands.find(brand => brand.idBrand === sticker.idBrand)) {
                    const brandSelected = allBrands.find(brand => brand.idBrand === sticker.idBrand)
                    props.setBrandPreview(`${hostname}/api/images/brands/${brandSelected.linkBrand}`)
                    if(allFlags.find(flag => flag.idFlag === brandSelected.idFlag)) {
                        const flagSelected = allFlags.find(flag => flag.idFlag === brandSelected.idFlag)
                        props.setFlagPreview(`${hostname}/api/images/flags/${flagSelected.linkFlag}`)
                    } else {
                        props.setFlagPreview(noImage)
                    }
                    
                } else {
                    props.setBrandPreview(noImage)
                    props.setFlagPreview(noImage)
                }

                for (const [key, value] of Object.entries(sticker)) {
                    if (value) {
                        props.setStickerValidation(state => {
                            return {
                                ...state,
                                [key] : true
                        }})
                    } 
                  }
                if(sticker.price2 && sticker.capacity2 && sticker.choicePg2 && sticker.pg2) {
                    props.setSecondPrice(true)
                } else {
                    props.setSecondPrice(false)
                }
                
            }
        })
        props.setUpdateBox(true)
    }

    const handlePrint = (e) => {
        e.preventDefault()
        props.setPrintBox(!props.printBox)
        
    }

    const handleDelete = (e) => {
        e.preventDefault()
        if(props.checkboxesChecked.length > 0) {
            props.setDeletionConfirmation(true)
        } else {
            props.setStatusRequest({
                status : false, 
                message : "Veuillez sélectionner une étiquette"
            })
        }
    }

    const handleClean = (e) => {
        e.preventDefault()
        props.setCheckboxesChecked([])
         props.setDataStickers(allStickers)
        
    }

    return(
        <div 
        className ="cart">
            <div className = {props.detectSelect ? "cart--button triggerWizz" : "cart--button"}  onClick = {handleClickCart}>
            <button >
                <span>{props.checkboxesChecked.length}</span>
            </button>
            </div>
            <div className = "cart__content" ref = {cart}>
                <div className = "cart__content__buttons">
                    <button onClick = {handlePrint}>Imprimer</button>
                    <button id = "delete"onClick = {handleDelete}>Supprimer la sélection</button>
                </div>
                {
                   props.checkboxesChecked.length > 5 ? 
                   <div className = "cart__clean">
                        <motion.button
                        exit = {{transform : "scale(0)"}}
                        animate = {{transform : "scale(1)"}}
                        initial = {{transform : "scale(0)"}}
                        onClick = {handleClean}
                        >
                                Vider la sélection
                        </motion.button>
                   </div>
                    :
                        null
                    
               }
                <ul className = "cart__content__list">

                    { props.checkboxesChecked.length === 0 ? 
                        <motion.li
                            exit = {{opacity : 0 }}
                            animate = {{opacity : 1 }}
                            initial = {{opacity : 0 }}
                            className = "cart__content__list__noData"
                        ><span>Aucune étiquette sélectionnée</span></motion.li>

                        :

                        allStickers.map((sticker) => (
                            <AnimatePresence>{
                            props.checkboxesChecked.map((checked, index) => (
                                checked === sticker.idLiquid ? 
                                    <motion.li 
                                        exit = {{x : -500}}
                                        animate = {{x : 0 }}
                                        initial = {{x : -500 }}
                                        transition = {{
                                            default : {duration : 0.2}
                                        }}
                                        key = {sticker.idLiquid}
                                    >
                                        <button className = "deselect" onClick = {(e) => handleDeselect(sticker.idLiquid)}>-</button>
                                        {
                                            allBrands.find(brand => brand.idBrand === parseInt(sticker.idBrand)) ? 
                                                <img src = {`${hostname}/api/images/brands/${allBrands.find(brand => brand.idBrand === parseInt(sticker.idBrand)).linkBrand}`} alt = {allBrands.find(brand => brand.idBrand === parseInt(sticker.idBrand)).nameBrand} />
                                            : 
                                                <img src = {noImage} alt = "Pas de marque liée"/>
                                        }

                                        <span>{sticker.title1} {sticker.title2}</span>
                                        <button className = "update" onClick = {(e)=> handleUpdate(e, checked)}><img src = {editIcon} alt = "edit"/></button>

                                    </motion.li>
                                : 

                                null
                            ))
                            }</AnimatePresence>
                        ))
                    }
                </ul> 
            </div>
        </div>
    )
}

function CatalogContent(props) {
    useEffect(() => {
        if(props.detectSelect) {
            const timer = setTimeout(() => {
                props.setDetectSelect(false)
            }, 650);
            return () => clearTimeout(timer);
        }
    })

    const handleOnChangeCheckedState = async(position) => {
        const checkboxSelected = props.dataStickers.filter((sticker, index) => index === position)
        const checkboxesCheckedCurrent = props.checkboxesChecked
        checkboxesCheckedCurrent.push(checkboxSelected[0].idLiquid)
        props.setCheckboxesChecked(checkboxesCheckedCurrent)
        
        const dataFilter = await props.dataStickers.filter(sticker => !props.checkboxesChecked.includes(sticker.idLiquid))
        props.setDataStickers(dataFilter)
        props.setDetectSelect(true)
    }

    const allBrands = useSelector(brandsSelector)
    const allFlags = useSelector(flagsSelector)


    return (
        <motion.div 
        exit = {{x : -800}}
        animate = {{x : 0}}
        initial = {{x : -800}}
        className = "catalog__table">
            <table>
                <thead>
                <tr>
                    <th>Marque</th>
                    <th>Nom du liquide</th>
                    <th>Goût</th>
                    <th>Contenance</th>
                    <th>Taux</th>
                    <th>Prix</th>
                    <th>Pays</th>
                </tr>
                </thead>
                <tbody>
                {Object.entries(props.dataStickers).length > 0 ? 
                <AnimatePresence>{
                props.dataStickers.map((sticker, index) => (
                    <motion.tr 
                        exit = {{x : 1000, }}
                        animate = {{x : 0 }}
                        initial = {{x : 1000}}
                        transition = {{
                            type : "linear", 
                        }}
                        key = {`${sticker.idLiquid}-${sticker.title1}`} 
                        onClick ={(e) => handleOnChangeCheckedState(index,e)}>
                        <td>
                        {
                            allBrands.find(brand => brand.idBrand === parseInt(sticker.idBrand)) ? 
                                <img src = {`${hostname}/api/images/brands/${allBrands.find(brand => brand.idBrand === parseInt(sticker.idBrand)).linkBrand}`} alt = {allBrands.find(brand => brand.idBrand === parseInt(sticker.idBrand)).nameBrand} />
                            : 
                                <img src = {noImage} alt = "Pas de marque liée"/>
                        }
                        </td>
                        <td>{sticker.title1} {sticker.title2}</td>
                        <td>{sticker.taste1} {sticker.taste2} {sticker.taste3}</td>
                        {sticker.capacity2 ? 
                            <React.Fragment>
                                <td>
                                    <span>{sticker.capacity1}ml</span>
                                    <span>{sticker.capacity2}ml</span>
                                </td>
                                <td>
                                <span>{sticker.pg1}{sticker.choicePg1}/{100 - sticker.pg1}VG</span>
                                <span>{sticker.pg2}{sticker.choicePg2}/{100 - sticker.pg2}VG</span>
                                </td>
                                <td>
                                    <span>{sticker.price1}€</span>
                                    <span>{sticker.price2}€</span>
                                </td>
                            </React.Fragment>
                        : 
                            <React.Fragment>
                                <td>{sticker.capacity1}ml</td>
                                <td>{sticker.pg1}{sticker.choicePg1}/{100 - sticker.pg1}VG</td>
                                <td>{sticker.price1}€</td>
                            </React.Fragment>
                        }
                        <td>
                            {
                                allBrands.find(brand => brand.idBrand === parseInt(sticker.idBrand)) ? 
                                    allFlags.filter(flag => flag.idFlag === parseInt(allBrands.find(brand => brand.idBrand === parseInt(sticker.idBrand)).idFlag)).length > 0 ? 
                                        <img src = {`${hostname}/api/images/flags/${allFlags.find(flag => flag.idFlag === parseInt(allBrands.find(brand => brand.idBrand === parseInt(sticker.idBrand)).idFlag)).linkFlag}`} alt = "kje" />

                                    : 
                                    <img src = {noImage} alt = "Pas de drapeau lié"/>

                                :
                                    <img src = {noImage} alt = "Pas de drapeau lié"/>
                            }

                        </td>
                    </motion.tr>
                ))
                 }</AnimatePresence>
             : 
             <motion.tr 
                exit = {{opacity : 0 }}
                animate = {{opacity : 1 }}
                initial = {{opacity : 0 }}
                id = 'catalog__table--noData'>
                    <td colSpan = {12}>Aucune étiquette</td>
             </motion.tr>
            }
                </tbody>
            </table>
        </motion.div>
    )
}